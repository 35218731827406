export default function Underbar() {
  return (
    <div className="w-full h-[170px] px-5 text-xs leading-relaxed pt-10 text-[11.5px] sm:text-[14px] lg:flex mb-10">
      <div className="lg:w-1/2">
        <div className="flex items-center mb-2 w-[327px] sm:w-[460px]">
          <img
            src="/assets/logo/under_munchebu.svg"
            alt="문화체육관광부"
            className="pr-5 w-[35%]"
          />
          <img
            src="/assets/logo/under_hanmunwi.svg"
            alt="한국문화예술위원회"
            className="w-[43%] pr-5"
          />
          <img
            src="/assets/logo/under_onmiye.svg"
            alt="온라인미디어 예술활동"
            className="w-[22%]"
          />
        </div>
        <div>
          2023년 문화체육관광부와 한국문화예술위원회의 <br /> ‘온라인미디어
          예술활동 지원사업’의 지원을 받아 제작되었습니다.
        </div>
      </div>
      <div className="pb-12 mb-24 lg:w-1/2">
        <div className="flex items-center pt-10 lg:pt-0">
          <div className="flex items-center mt-2 lg:w-1/2">
            <img
              src="/assets/logo/flipthefloor final.svg"
              alt="flip the floor"
              className="mb-2 h-7"
            />
          </div>
          <div className="pl-4 mt-2 sm:pl-10 lg:w-1/2">
            <p>E. peelthepeel@gmail.com</p>
            <p>
              IG.&nbsp;
              <a href="https://www.instagram.com/flipthefloor/">
                @flipthefloor
              </a>
            </p>
          </div>
        </div>
        <p className="pt-2 lg:pt-0">
          Copyright © flipthefloor All Rights Reserved
        </p>
      </div>
    </div>
  )
}
