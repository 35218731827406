import { useEffect } from "react"
import { Unity, useUnityContext } from "react-unity-webgl"

const params = "LGH_1010_backtest"

export default function House() {
  const {
    unityProvider,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
  } = useUnityContext({
    loaderUrl: `Build/${params}.loader.js`,
    dataUrl: `Build/${params}.data`,
    frameworkUrl: `Build/${params}.framework.js`,
    codeUrl: `Build/${params}.wasm`,
  })

  useEffect(() => {
    return () => {
      detachAndUnloadImmediate().catch((reason) => {
        console.log(reason)
      })
    }
  }, [detachAndUnloadImmediate])

  return (
    <div className="fixed w-screen h-[90vh]">
      <div className="grid w-full h-full place-items-center">
        <div className="cell">
          <div className="wrapper">
            <div className="spinner spinner1"></div>
          </div>
        </div>
        <Unity
          unityProvider={unityProvider}
          className="w-[330px] h-[248px] sm:w-[490px] sm:h-[368px] md:w-[640px] md:h-[480px] lg:w-[800px] lg:h-[600px] nxl:w-[1024px] nxl:h-[768px] fixed z-10"
        ></Unity>
      </div>
    </div>
  )
}
