import { useNavigate } from "react-router-dom"
import { menu } from "../components/Navbar"
import Underbar from "../components/MainUnderbar"

export default function Main() {
  const navigate = useNavigate()
  const title: string = "pb-2 font-medium text-[20px]"

  return (
    <div className="">
      <img
        src="/assets/logo/홈로고 최종.png"
        alt=""
        className="w-full mb-10 grad1"
      />

      <div className="justify-between w-full px-5 py-10 lg:flex ">
        <div className="sm:w-[507px] lg:w-[37.5%] lg:pr-5">
          <p className={title}>리미널 할머니집</p>
          <p className="text-[15px] leading-[26px] pb-7">
            인터렉티브 웹 전시 &#60;리미널 할머니집&#62;은 노스탤지어를 상징하는
            안락한 ‘할머니집’과 으스스하고 불길한 텅 빈 공간을 이르는 ‘리미널
            스페이스’의 이미지를 시청각적으로 결합하는 초현실주의 공간 구현
            작업이다.
          </p>
          <p className={title}>Liminal Grandma’s House</p>
          <p className="text-[15px] leading-[26px] pb-7">
            The interactive web exhibition &#60;Liminal Grandma's House&#62; is
            a comfortable “Grandma's House” symbolizing Nostalgia and eerieA
            surrealist space that audiovisually combines images of ‘liminal
            space’ that lead to ominous empty spaces. It's an implementation
            task.
          </p>
        </div>
        <img
          className="w-full sm:w-[507px] lg:w-[62.5%]"
          src="/assets/image/홈 캡쳐 최종.png"
          alt="전시 메인 이미지"
        ></img>
      </div>

      <div className="flex justify-between px-5 py-10">
        {menu.map(({ name, path }) => {
          return (
            <div className="w-[24%] h-auto" key={path}>
              <img
                src={`/assets/image/${name}.png`}
                alt={name}
                className="cursor-pointer mainItem"
                onClick={() => navigate(path)}
              />
              <p className="pt-2 text-[15px] text-center">{name}</p>
            </div>
          )
        })}
      </div>
      <div className="h-[340px]"></div>
      <Underbar></Underbar>
    </div>
  )
}
