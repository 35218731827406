import { useState } from "react"
import Underbar from "../components/Underbar"
import { NavLink } from "react-router-dom"

function checkName(name) {
  const charCode = name.charCodeAt(name.length - 1)
  const consonantCode = (charCode - 44032) % 28

  if (consonantCode === 0) {
    return `를`
  }
  return `을`
}

const itemList = [
  {
    name: "미지근한 달걀",
    number: "01",
    explain:
      "달걀! 달걀은 완전식품으로 불릴 만큼 풍부한 영양분을 제공합니다. 대부분의 문화권에서 요리 재료로 활용되고 있다고 하네요. 하지만 신선하지는 않아 보입니다. 안타깝게도…",
    text: "언제 어디서나 비상식량",
    english: "egg",
  },
  {
    name: "비범한 양념통",
    number: "02",
    explain:
      "평범해 보이지만 조미료가 무한히 솟아나는 궁극의 양념통입니다. 당신은 할머니집의 조미료가 다 떨어진 것을 한 번이라도 본 적 있습니까?",
    text: "당신의 요리를 책임집니다.",
    english: "sauce jar",
  },
  {
    name: "식물이 담긴 화분",
    number: "03",
    explain:
      "자판기에서 살아 있는 생물이 나오다니요? 하지만 놀라울 정도로 건강합니다. 누군가 정성껏 보살피던 것 같습니다. 이사 및 개업 선물로도 유용합니다.",
    text: "언제까지나 파릇파릇합니다.",
    english: "plant",
  },
  {
    name: "X심 커피병",
    number: "04",
    explain:
      "커피로 유명한 브랜드의 인스턴트 커피입니다. 뜨거운 물에 녹여 마실 수 있습니다. 하지만 지난 10년간 아무도 이 병에 손을 대지 않은 것 같더군요…",
    text: "수상할 정도로 높은 카페인 함유량",
    english: "coffee",
  },
  {
    name: "개봉박두 밥상보",
    number: "05",
    explain:
      "이 범용성 높은 음식물 보존 도구는 통기성과 비가시성을 겸비했습니다. 할머니가 정성껏 준비한 저녁 밥상에 먼지나 벌레가 앉는 것을 막아줄 뿐 아니라 당신이 감히 식사 메뉴를 예측할 수 없게 합니다!",
    text: "오늘 저녁 메뉴는?",
    english: "table cover",
  },
  {
    name: "오래된 화장품",
    number: "06",
    explain:
      "유통기한이 없습니다. 10년 전에도 같은 곳에 있었던 것 같지만 잘 기억나지 않습니다. 써도 괜찮을까요? 아무도 알 수 없습니다…",
    text: "세상에 다시 없을 강렬한 색조",
    english: "cosmetic",
  },
  {
    name: "구식 라디오",
    number: "07",
    explain:
      "전파를 통한 음성 정보 수신이 가능한 구식 단말기입니다. 카세트 테이프를 재생하는 기능도 겸비했습니다. 반세기 전까지 가장 인기있는 엔터테인먼트 매체 중 하나였다고 합니다.",
    text: "비상시에도 유용합니다.",
    english: "radio",
  },
  {
    name: "X랑방 캔디 통",
    number: "08",
    explain:
      "사탕을 제외한 모든 것을 담을 수 있습니다. 어린이들을 희망고문하는 패키지 디자인이 특징입니다. 공장에서 캔디가 들어 있지 않은 채로 출고된다는 루머가 있습니다. 과연 그럴까요?",
    text: "캔디 없는 캔디통",
    english: "candy",
  },
  {
    name: "전형적인 전화기",
    number: "09",
    explain:
      "통화 아이콘이 왜 그런 모양인지 궁금하다면 이 전화기를 보십시오! 클래식한 생김새와 직관적인 버튼이 매력적입니다. 할머니는 가끔 이 전화를 쳐다보시면서, 당신으로부터 안부 전화가 걸려오지 않을까 생각하시곤 한답니다.",
    text: "가장 전화기다운 생김새를 지닌 전화기",
    english: "phone",
  },
  {
    name: "바가지",
    number: "10",
    explain:
      "컴팩트한 크기의 플라스틱 바가지입니다. 식물인 ‘박’에 접미사 ‘아지’를 붙인 순우리말 이름이라고 하네요. 동네 약수터가 주 원산지인 것으로 알려져 있습니다. ",
    text: "동네 뒷산 등산시 필수품!",
    english: "bagaji",
  },
]

export default function Vending() {
  const [step, setStep] = useState<number>(0)
  const [open, setOpen] = useState<string>("")
  const [item, setItem] = useState<number>(-1)
  const [deco, setDeco] = useState<[string, number, string]>(["", -1, ""])

  return (
    <div>
      <div className="flex justify-center w-full">
        {step === 0 ? (
          <div className="flex justify-center sm:w-[544px] w-[405px]">
            <img
              src="/assets/vending/VD_final.png"
              alt="자판기"
              className={`w-[300px] sm:w-[500px] cursor-pointer mt-2 ${open}`}
              onClick={() => {
                setOpen("shake shine")
                setTimeout(() => {
                  if (item === -1) {
                    setItem(Math.floor(Math.random() * 10))
                  }
                }, 1500)
              }}
            />
            {item > -1 ? (
              <div className="absolute w-full h-full bg-[#00000044]"></div>
            ) : null}
            {item > -1 ? (
              <div className="sm:w-[393px] w-[327px] h-[493px] bg-black border-[1px] border-white z-10 absolute top-[190px] flex flex-wrap items-center justify-center popup pt-5">
                <div className="text-center h-[227px] text-[17px] font-medium">
                  <img
                    src={`/assets/vending/step1_${itemList[item].number}_${itemList[item].english}.png`}
                    alt="랜덤 아이템"
                    className="h-[210px] w-[210px]"
                  />
                  {itemList[item].name}
                  {checkName(itemList[item].name)} 뽑았다!
                </div>
                <div className="pt-[10px] px-6 text-center h-[180px] text-[15px] leading-[26px] font-normal">
                  {itemList[item].explain}
                </div>
                <div
                  className="border-white  border-t-[1px] px-2 hover:text-white text-[#bbbbbb] cursor-pointer py-2"
                  onClick={() => {
                    setStep(1)
                  }}
                >
                  NEXT
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {step === 1 ? (
          <div className="w-[544px] h-[813px] pt-10">
            <div className="flex flex-col items-center w-full">
              <div className="text-[17px]">
                <span className="font-medium">
                  &lt;{itemList[item].name}&gt;
                </span>
                {checkName(itemList[item].name)} 간직하자
              </div>
              {deco[1] === -1 ? (
                <img
                  src={`/assets/vending/step1_${itemList[item].number}_${itemList[item].english}.png`}
                  alt="랜덤 아이템"
                  className="sm:h-[326px] h-[252px]"
                />
              ) : null}
              {deco[1] > -1 ? (
                <img
                  src={`/assets/vending/${deco[0]}_${itemList[item].number}(${itemList[item].english}).gif`}
                  alt="꾸민 아이템"
                  className="sm:h-[326px] h-[252px]"
                />
              ) : null}
              <div className="flex justify-between font-normal text-[15px]">
                <div
                  className="p-6 text-center cursor-pointer"
                  onClick={() => setDeco(["Keyholder", 2, "키링"])}
                >
                  <img
                    src="/assets/vending/Keyholder.png"
                    className="sm:h-[160px] w-auto h-[100px] mb-[24px]"
                    alt=""
                  />
                  키링
                </div>
                <div
                  className="p-6 text-center cursor-pointer"
                  onClick={() => setDeco(["Snowball", 3, "스노우볼"])}
                >
                  <img
                    src="/assets/vending/Snowball.png"
                    className="sm:h-[160px] w-auto h-[100px] mb-[24px]"
                    alt=""
                  />
                  스노우볼
                </div>
                <div
                  className="p-6 text-center cursor-pointer"
                  onClick={() => setDeco(["Ring", 1, "반지"])}
                >
                  <img
                    src="/assets/vending/Ring.png"
                    className="sm:h-[160px] w-auto h-[100px] mb-[24px]"
                    alt=""
                  />
                  반지
                </div>
              </div>
              {deco[1] > -1 ? (
                <div
                  className="border-white  border-t-[1px] px-2 hover:text-white text-[#bbbbbb] cursor-pointer py-2 mt-[25px]"
                  onClick={() => {
                    setStep(2)
                  }}
                >
                  NEXT
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {step === 2 ? (
          <div className="h-[813px] pt-10 flex flex-col items-center text-[17px]">
            <div>
              <span className="font-medium">&lt;{itemList[item].name}&gt;</span>{" "}
              &nbsp;
              {deco[2]}
              {checkName(deco[2])} 획득했다!
            </div>
            <div className="sm:h-[589px] h-[470px] sm:w-[408px] w-[326px] pt-14">
              <div className="w-full h-full">
                <img
                  src={`/assets/vending/txt ${deco[1]}-${parseInt(
                    itemList[item].number
                  )}.jpg`}
                  alt="카드 틀"
                  className="sm:h-[589px] h-[470px] sm:w-[408px] w-[326px]"
                />
                <img
                  src={`/assets/vending/${deco[0]}_${itemList[item].number}(${itemList[item].english}).gif`}
                  alt="꾸민 아이템"
                  className="relative sm:bottom-[520px] bottom-[420px] sm:h-[320px] h-[260px] -translate-x-1/2 left-1/2"
                />
              </div>
            </div>
            <div className="pt-[80px] w-[320px] h-[100px] flex justify-between">
              <div
                className="border-white  border-t-[1px] px-2 hover:text-white text-[#bbbbbb] cursor-pointer py-2 mt-[25px]"
                onClick={() => {
                  setStep(0)
                  setItem(-1)
                  setDeco(["", -1, ""])
                  setOpen("")
                }}
              >
                다시하기
              </div>
              <a
                className="border-white  border-t-[1px] px-2 hover:text-white text-[#bbbbbb] cursor-pointer py-2 mt-[25px]"
                href={`/assets/vending/${deco[1]}-${parseInt(
                  itemList[item].number
                )}.jpg`}
                download={`${itemList[item].name} ${deco[2]}.jpg`}
              >
                저장하기
              </a>
              <NavLink to="/">
                <div
                  className="border-white  border-t-[1px] px-2 hover:text-white text-[#bbbbbb] cursor-pointer py-2 mt-[25px]"
                  onClick={() => {
                    setStep(2)
                  }}
                >
                  홈으로
                </div>
              </NavLink>
            </div>
          </div>
        ) : null}
      </div>
      <div className="w-full">
        <Underbar></Underbar>
      </div>
    </div>
  )
}
