import { useRef, useState } from "react"
import {
  Kyoungmin,
  Minkyoung,
  Lin,
  Subee,
  Minsu,
  Seungchan,
  Naeun,
  Hajeong,
} from "../components/Interviews"
import Underbar from "../components/Underbar"

const interviewList = [
  { name: "27세, 여성, 외할머니와 살고 있음" },
  { name: "27세, 여성, 서울 거주" },
  { name: "25세, 여성, 청주에 사는 대학생" },
  { name: "26세, 여성, 평범한 직장인" },
  { name: "38세, 남성, 여행사 운영 및 육아 중" },
  { name: "27세, 남성, 통학하는 대학생" },
  { name: "29세, 여성, 박물관 전시 업계" },
  { name: "31세, 여성, 무역업계 회사원" },
]

export default function Interview() {
  const [nowInterview, setNowInterview] = useState<number>(-1)
  const modalRef = useRef<HTMLDivElement | null>(null)
  const [closeInterview, setCloseInterview] = useState<string>("")

  function closeModal() {
    setCloseInterview("fadeOutRight")
    setTimeout(function () {
      setNowInterview(-1)
      setCloseInterview("")
    }, 1600)
  }

  function scrollUp() {
    // console.log(modal)
    // modal!.scrollIntoView({ behavior: "smooth", block: "center" })
    // console.log("야")
    modalRef!.current!.scrollTop = 0
  }

  return (
    // <div className={`${scrollStop}`}>
    <div className="">
      <div
        className="flex justify-center px-5 pt-5"
        onClick={(e: { target: any }) => {
          if (nowInterview >= 0 && !modalRef!.current!.contains(e.target)) {
            closeModal()
          }
        }}
      >
        {nowInterview >= 0 ? (
          <div className="w-full h-full bg-[#00000055] fixed"></div>
        ) : null}
        {nowInterview >= 0 ? (
          <div
            ref={modalRef}
            id="modal"
            className={`${closeInterview} fadeInRight animate fixed w-[327px] sm:w-[461px] md:w-[553px] lg:w-[816px] nxl:w-[1086px] p-6 bg-[#252525] top-[145px] sm:top-[100px] lg:top-[74px] h-screen overflow-y-scroll nonScrollbar`}
          >
            <div
              onClick={() => {
                closeModal()
              }}
              style={{
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
              }}
              className="cursor-pointer hoverbackward w-[30px] h-[30px]"
            />
            <div className="text-[20px] font-normal py-3">
              {interviewList[nowInterview].name}
            </div>
            {nowInterview === 0 ? <Kyoungmin /> : null}
            {nowInterview === 1 ? <Minkyoung /> : null}
            {nowInterview === 2 ? <Lin /> : null}
            {nowInterview === 3 ? <Subee /> : null}
            {nowInterview === 4 ? <Minsu /> : null}
            {nowInterview === 5 ? <Seungchan /> : null}
            {nowInterview === 6 ? <Naeun /> : null}
            {nowInterview === 7 ? <Hajeong /> : null}

            <div className="w-full h-[220px] flex justify-center">
              <div
                style={{
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
                className="w-[30px] h-[30px] cursor-pointer hoverup mt-[100px]"
                onClick={() => {
                  scrollUp()
                }}
              />
            </div>
          </div>
        ) : null}
        <div className="grid grid-cols-4 gap-y-[32px] sm:gap-x-[24px]">
          {interviewList.map(({ name }, index) => {
            return (
              <div
                key={name}
                className="w-[268px] h-[351px] md:w-[330px] md:h-[430px] col-span-4 border-[#ffffff88] border-[1px] sm:col-span-2 nxl:col-span-1 bg-center cursor-pointer"
                onClick={() => {
                  setNowInterview(index)
                }}
              >
                <div
                  className="w-full h-[301px] md:h-[380px] bg-center"
                  style={{
                    backgroundImage: `url(/assets/interview/${index}.png)`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <div className="w-full h-[50px] flex items-center justify-between pl-3 pr-2 text-[15px]">
                  <p className="w-full">{name}</p>
                  <div
                    style={{
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                    }}
                    className="w-[30px] h-[30px] cursor-pointer hoverforward"
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Underbar></Underbar>
    </div>
  )
}
