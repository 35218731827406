import { useEffect, useState } from "react"
import { http } from "../api/axios"

type commentType = {
  id: number
  nickname: string
  content: string
  password: string
  deleted: number
}

type checkType = [number, number]

export default function Community() {
  const [replyContent, setContent] = useState<string>()
  const [replyNickname, setNickname] = useState<string>()
  const [replyPassword, setPassword] = useState<string>()
  const [deletePassword, setDeletePassword] = useState<string>()
  const [position, setPosition] = useState<checkType>([0, 0])
  const [deleteComment, setDeleteComment] = useState<commentType>()
  const [clicked, setClicked] = useState<string>("hidden")
  const [wrong, setWrong] = useState<string>("hidden")
  const [comments, Setcomments] = useState<commentType[]>([])

  async function getComments() {
    const data = await http.get(`/read`)
    const reversedData = data.data.reverse()
    Setcomments(reversedData)
  }

  useEffect(() => {
    getComments()
  }, [])

  return (
    <div className="px-5 pt-5 sm:flex">
      <div
        className={`${clicked} z-10 w-[301px] h-[179px] bg-black border-[0.8px] border-white absolute right-[17px] top-[45px] text-[15px] pt-4 pl-4`}
        style={{ top: position[0] + 30, left: position[1] - 301 }}
      >
        선택한 글을 삭제하시겠습니까?
        <textarea
          className="w-[93.5%] h-[44px] resize-none bg-[#252525] pt-2 pl-2 text-[12px] mt-4 border-white border-b-[0.8px] items-center flex"
          onChange={(e) => setDeletePassword(e.target.value)}
          placeholder="비밀번호를 입력해주세요."
        ></textarea>
        <span className={`${wrong} text-[10px] text-[#76A396]`}>
          비밀번호가 일치하지 않습니다.
        </span>
        <div
          className="w-[65px] h-[44px] absolute bottom-0 right-[65px] border-r-[0.8px] border-white cursor-pointer bg-[#252525] flex justify-center items-center"
          onClick={() => {
            async function delComment() {
              await http.post(`delete`, { id: deleteComment!.id })
              window.location.reload()
            }
            if (deletePassword === deleteComment!.password) {
              delComment()
            } else {
              setWrong("visible")
            }
          }}
        >
          삭제
        </div>
        <div
          className="w-[65px] h-[44px] absolute bottom-0 right-0 cursor-pointer flex justify-center items-center"
          onClick={() => {
            setClicked("hidden")
            setWrong("hidden")
          }}
        >
          취소
        </div>
      </div>
      <div className="mb-24">
        <div className="text-[15px] w-[310px] h-full sm:fixed px-2">
          <hr className="border-[#bbbbbb]" />
          <p className="pt-4 pb-2">당신의 할머니집은 어떤 곳이었나요?</p>
          <ul
            style={{ listStyle: "disc inside" }}
            className="text-[13px] leading-[23px] pl-2 pb-8"
          >
            <li>누가 살고 있나요? 누가 살았던 곳인가요?</li>
            <li>가장 선명한 기억은 무엇인가요? </li>
            <li>할머니집이 낯설게 느껴진 적이 있나요?</li>
            <li>가장 먼저 떠오르는 물건이나 공간이 있나요?</li>
            <li> 금지된 장소나 행동이 있나요?</li>
            <li>어린 시절과 비교해 무엇이 달라졌나요?</li>
          </ul>
          <div>
            <hr className="border-[#bbbbbb]" />
            <textarea
              className="w-full h-10 resize-none bg-[#252525] block p-2"
              onChange={(e) => setNickname(e.target.value)}
              placeholder="이름을 적어주세요."
            ></textarea>
            <hr className="border-[#bbbbbb]" />

            <textarea
              className="w-full h-[280px] resize-none bg-[#252525] p-2 leading-[26px] block"
              onChange={(e) => setContent(e.target.value)}
              placeholder="당신에게 할머니집은 어떤 공간이었나요?"
            ></textarea>
            <hr className="border-[#bbbbbb]" />
            <input
              className="w-full h-10 resize-none bg-[#252525] block p-2"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 8자리 이내로 입력해주세요"
              maxLength={8}
            ></input>
            <hr className="border-[#bbbbbb]" />
            <button
              className="h-10 text-center hover:text-white text-[#bbbbbb] w-[65px] float-right border-[#bbbbbb] border-r-[1px]"
              onClick={() => {
                async function makeComment() {
                  await http
                    .post(`write`, {
                      nickname: replyNickname,
                      password: replyPassword,
                      content: replyContent,
                      deleted: false,
                    })
                    .then((res) => console.log("완료"))
                  window.location.reload()
                }
                makeComment()
              }}
            >
              SEND
            </button>
          </div>
        </div>
        <div className="w-[310px] h-full bg-black"></div>
      </div>
      <div className="grid grid-cols-10 gap-6 mt-3 sm:pl-6">
        {comments.map(({ id, nickname, content, password, deleted }) => {
          if (deleted === 0) {
            return (
              <div
                key={nickname}
                className="col-span-10 xl:col-span-5 bg-[#252525] p-4 leading-[26px] relative"
              >
                <div className="pb-2 text-[13px] flex w-full justify-between">
                  <span>{nickname}</span>
                  <img
                    src="/assets/ui/meatballs.svg"
                    alt="meatballs menu"
                    className="w-5 cursor-pointer"
                    id="button"
                    onClick={(e) => {
                      var target = e.target as HTMLImageElement
                      var winY = window.scrollY
                      setPosition([
                        target!.getBoundingClientRect().top + winY,
                        target!.getBoundingClientRect().right,
                      ])
                      setDeleteComment({
                        id,
                        nickname,
                        content,
                        password,
                        deleted: 0,
                      })
                      setClicked("visible")
                    }}
                  />
                </div>
                <p className="text-[15px]">{content}</p>
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
