import { useState } from "react"
import Underbar from "../components/Underbar"
import parse from "html-react-parser"

const aboutMenus = [
  {
    kor: "전시 소개",
    korIntro: `인터랙티브 웹 전시 <리미널 할머니집>은 신체 및 정서적인 안락함과 노스탤지어의 화신으로서 ‘할머니집’이라는 공간의 상징성을, 필수 요소와 생명체의 부재로 형성되는 불길하고 으스스한 문턱(limen) 공간을 이르는 신조어 ‘리미널 스페이스(liminal space)’와 융합하는 초현실주의 공간 구현 작업이다. ‘리미널 스페이스’는 호러 장르 기반의 온라인 서브컬처에서 파생된 개념으로 일상적인 공간이 공동화, 맥락의 삭제, 오배치 등 원인을 정확히 파악할 수 없는 결핍 혹은 변형으로 인해 불안과 위협의 정서를 야기하는 이미지를 통칭한다. 일상성과 정상성의 수호 아래 있던 익숙한 공간이 변칙성을 띌 때 발생하는 괴리는 그 자체로 보편적인 공포가 된다.
<br/>
    　　　할머니집은 가족과 어린 시절을 구심점으로 하는 노스탤지어(향수)의 상징적인 발원지이며 나의 가족이 발생한 곳이자 어린 아이로서 돌봄과 관심의 대상이 되었던 기억이 핵심이 되는 장소다. 그러나 동시에 할머니집은 혈연관계로부터 세습된 가부장제를 구심점으로 다양한 관계의 다이나믹이 태동하고 위계 폭력이 발생하는 동시에 묵인되는 현장이기도 하다. 할머니집이 제공하는 보살핌과 안락함은 때로 ‘나’의 주체성을 배제하고 타자화하며, 가족 구조가 제공하는 안정성과 정상성은 변칙적인 개인과 필연적으로 마찰한다. 한편 개인이 성장한 이후 할머니집은 수많은 맥락이 삭제된 채 일종의 가족-노스탤지어-유토피아로 자리매김한다. 자본주의 구조에서 소외를 경험한 개인이 회귀하고자 하는 노스탤지어의 공간 즉 할머니집은 총체성과 반복성이 수호하는 예상 가능하며 단순한 도피처로서 새로 지어진다. 
    <br/>
    　　　이러한 맥락에서 <리미널 할머니집>은 노스탤지어를 과거의 기계적인 복원이 아닌 현재 시점의 결핍이 낳은 퇴화의 방어기제로 파악한다. 물리적 실체로 빚어낸 이상화된 노스탤지어를 가상 초현실주의 웹 공간에 이식함으로서 관객이 반추 행위를 통해 정상성과 변칙성의 가변적인 역학 관계를 목격하고 성장의 의미와 과정에 대해 고민하도록 의도한다.`,
    eng: "Introduction",
    engIntro: `The interactive web exhibition &lt;Liminal Grandma's House> is a surreal space building project in which we combined "grandmother's house", symbolizing physical and emotional comfort as well as nostalgia's embodiment, with the concept of "liminal space". Liminal space is a concept that refers to an ominous and eerie space created by absence of essential elements and living organisms. Derived from online subculture based on horror genres, this concept generally indicates images that evoke a sense of threat and anxiety due to an unidentifiable absence or transformation, such as hollowing out, deletion of context, and misplacement. The gap that occurs when a familiar space, previously under the guard of everyday life and normality, takes on anomalism becomes a universal source of horror.
    <br/>　　　Grandmother’s house is considered as the origin of nostalgia, which symbolizes the sentiment of family and childhood. It's where my family occurred, and it holds the core memories of my childhood, filled with care and attention. However, at the same time, grandmother's house is also a place where various relationship dynamics are formed, and hierarchical violence is tolerated, all within the context of the patriarchal system inherited from blood relations. Grandmother's house provides care and comfort, but sometimes excludes and otherizes the independence of "myself". Further, the stability and normality of the family structure inevitably cause friction against anomalous individuals. After individuals grow up, the grandmother's house comes to remain as a Family-Nostalgia-Utopia, with numerous contexts deleted. Individuals who have experienced alienation within the capitalist structure eager to return to the space of nostalgia (i.e., grandmother's house); It is newly built as a simple and predictable refuge protected by totality and repeatability.
    <br/>　　　Therefore, &lt;Liminal Grandma's House> identifies nostalgia as a defense mechanism of regression due to a deficiency at present, not a mechanical restoration of the past. We implanted idealized nostalgia made from physical entities into a surrealistic virtual web space. In this space, the audience may witness the dynamics of normality and anomaly through rumination and consider the meaning and process of growth.
    `,
  },
  {
    kor: "할머니집",
    korIntro: `리미널 할머니집(visiting grandma)은 리미널 스페이스화된 가상의 할머니집 공간으로, 가족에 관련된 노스탤지어를 초현실주의 화풍으로 웹 환경에 축조한 결과물이다. 개인적이며 정서적인 유년의 기억과 가족(할머니)이라는 주제어 아래 다수가 공감하는 교집합의 조건을 포착하는 작업이 선행된다. 수집 후 분류, 정형화의 과정을 통과한 노스탤지어 이데아의 파편은 열화된 디지털 데이터와 3D 메쉬, 텍스쳐로 엉성하게 재현되어 가상공간에 업로드된다. 
    <br/>
    　　　관객은 웹페이지를 통해 진입한 익명의 ‘할머니집’에서 불길함을 감지한다. 공간의 소유자인 할머니와 가족 구성원은 나타나지 않고 전형적인 사물과 공간은 이동, 결합, 붕괴, 부재, 과잉, 대체, 패턴화 공정을 거쳐 인공적으로 노스탤지어를 생산하는 백그라운드 테마 음악과 함께 비선형적 시공간의 대체 현실 공간을 구성한다. 도면 없이 마구잡이로 재현된 3차원 노스탤지어는 기억의 가변성과 망각된 유년 시절의 트라우마가 야기한 여백을 인공지능이 자동 생성한 목적 없는 이미지로 채워나간다.
    <br/>
    　　　‘할머니집’은 외부와 차단된 고립과 회귀의 공간으로 옷장 문이라는 트리거를 통해 같은 공간의 대과거로 반복해 되돌아가도록 유도한다. 정상가족으로 상징되는 구조와 관계의 폭력, 망각에 대한 근원적인 두려움, 성장과 탈가족 과정에서 겪는 소외는 리미널 스페이스가 주는 불쾌감과 낯선 공포(언캐니)와 조응하여 할머니집 공간에서 발생하는 모순과 부조리의 정서를 극대화한다. 퇴행적 방어 기제로서의 노스탤지어는 마침내 관객의 직시를 통해 붕괴하며, 할머니집으로부터 탈출한다는 선택지를 통해 주체적으로 망각된다.`,
    eng: "Visiting Grandma",
    engIntro: `&lt;Visiting Grandma> is a liminal-spatialized virtual grandmother's house, resulting from the construction of family-related nostalgia in a surrealistic style within a web environment. Under the theme of personal and emotional childhood memories and family (grandmother), we preceded a work that captures the intersecting conditions that many can relate to. After collection, the fragments of ideal nostalgia, classified and standardized, were loosely represented with digital data, 3D mesh, and texture, then uploaded to the virtual space.
    <br/>　　　The audience senses ominousness, when entering the anonymous "grandmother's house" through the web page. The owner of the space, grandmother and family members, do not show up there. However, familiar objects and spaces in daily life change into surrealistic forms through rearrangement, combination, collapse, absence, excess, substitution, and patterning processes. Together, they form an alternate reality within a nonlinear space-time framework, accompanied by background music designed to evoke an artificial sense of nostalgia. Three-dimensional Nostalgia, randomly represented without blueprint, fills the margins caused by memory variability and trauma from forgotten childhood with aimless images automatically generated by artificial intelligence.
    <br/>　　　Grandmother's house is a space of isolation and regression, blocked from the outside world. The closet door triggers repetitive returns to the same space. Structural and relational violence symbolized by a normal family. A fundamental fear of oblivion. Alienation experienced in the process of growing up and leaving the family. These correspond to unpleasantness and uncanny fear of the liminal space and maximize the emotions of contradiction and absurdity that occur in the space of the grandmother's house. Nostalgia, a regressive defense mechanism, finally collapses when audience face it. And it is independently forgotten through the option of escaping from the grandmother's house.`,
  },
  {
    kor: "노스탤지어 자판기",
    korIntro: `노스탤지어 자판기(Vending machine)는 할머니집이 제공하는 핵심 가치인 노스탤지어의 개인성과 추상성을 자판기가 제공하는 상품의 통일성, 물질성과 충돌시키는 작업이다. 자판기는 할머니집 공간이 대표하는 정서에 대한 다수의 데이터를 범주화, 압축한 후 사물의 단위로 대량 생산 및 복제하여 최종적으로 관객의 실제 기억과 무관한 ‘레디메이드’ 노스탤지어를 제안한다. 자판기가 출력하는 오브제는 관객의 공감 여부와 관계없이 양산된 가짜 노스탤지어를 전면에 표방하지만, 한편으로 실제 인물의 노스탤지어에 기반한다는 아이러니를 포함한다. 
    <br/>
    　　　이때 상품은 다시 ‘기념품’으로, 가장 대중적이면서 상징적인 형태(반지, 스노우볼, 열쇠고리)로 재가공된다. 기념품 소비가 랜드마크를 열화해 복제한 상품의 구매를 통해 노스탤지어에 물성을 부여하는 자본주의적인 추억 행위일 때, 할머니집 자판기는 불가침 영역에 있던 우상화된 노스탤지어를 기념품으로 치환하는 행위를 통해 물성과 금전 가치를 강제로 삽입하고 관객의 경험과 인식에 기반한 아우라를 제거한다. 이렇게 데이터화된 ‘할머니집 기념품 오브제’는 관객의 이미지 저장, 온라인(SNS) 공유라는 최종 행위를 통해 다시 한번 무한 복제, 대량 생산, 전시라는 온라인 자판기의 노스탤지어 파괴 매커니즘을 반복 경험한다.`,
    eng: "Vending Machine",
    engIntro: `The &lt;Vending Machine> collides the individuality and abstraction of Nostalgia, which are core values of grandmothers' house, with the De-individuality and materiality of products provided by vending machines. First of all, the vending machine categorizes and compresses a large number of data on the emotions represented by the grandmother's house. And the data is mass-produced and replicated as objects, ultimately suggesting a 'ready-made' nostalgia that is unrelated to the audience's actual memories. The objects produced by the vending machine prominently represent a manufactured, fake nostalgia that is unrelated to the audience's empathy. However, they incorporate an irony based on the actual nostalgia of real individuals.
    <br/>　　　The product is reprocessed into a 'souvenirs' in the most popular and symbolic form (e.g., rings, snowballs, and key chains). Souvenir consumption is the act of purchasing replica products of landmarks and is a ritual of the capitalist system that materializes nostalgia. &lt;Vending Machine> converts idealized Nostalgia into souvenirs to materialize and capitalize. Audiences store and share images of the data-generated 'Grandmother's House Souvenir Object' online. This action perpetuates the nostalgia destruction mechanism of online vending machines: infinite cloning, mass production, and exhibition.
    `,
  },
  {
    kor: "크레딧",
    korIntro: "",
    eng: "Credit",
    engIntro: "",
  },
]

const korCredit = [
  { job: "PM", name: "오은정" },
  {
    job: "기획·진행",
    name: "flipthefloor (김현아 박주희 오은정 이은서 최민경)",
  },
  { job: "게임 기획", name: "flipthefloor" },
  { job: "3D 제작", name: "오은정 이은서" },
  { job: "그래픽 디자인", name: "김현아" },
  { job: "사운드", name: "이은서" },
  { job: "글", name: "최민경" },
  { job: "인터뷰 기획·진행", name: "박주희 오은정" },
  { job: "웹 디자인", name: "김현아" },
  { job: "웹 개발", name: "채송지" },
  { job: "Unity 개발", name: "임효정" },
  { job: "Unity 라이팅", name: "박문주" },
  { job: "번역", name: "제이 리" },
  { job: "주최", name: "flipthefloor" },
  {
    job: "후원",
    name: "문화체육관광부, 한국문화예술위원회, 2023년 온라인미디어 예술활동 지원사업",
  },
]

const engCredit = [
  { job: "PM", name: "Eunjeong Oh" },
  {
    job: "Directed by",
    name: "flipthefloor (Hyunah Kim, Juhee Park, Eunjeong Oh, Eunseo Lee, Minkyung Choi)",
  },
  { job: "Game Directed by", name: "flipthefloor" },
  { job: "3D", name: "Eunjeong Oh, Eunseo Lee" },
  { job: "Graphic Design", name: "Hyunah Kim" },
  { job: "Sound", name: "Eunseo Lee" },
  { job: "Writing", name: "Minkyung Choi" },
  { job: "Interview", name: "Juhee Park, Eunjeong Oh" },
  { job: "Web Design", name: "Hyunah Kim" },
  { job: "Web Development", name: "Songji Chai" },
  { job: "Unity Development", name: "Hyojeong Lim" },
  { job: "Unity Lighting", name: "Munju Park" },
  { job: "Translation", name: "Jay Lee" },
  // { job: "주최", name: "" },
  { job: "Sponsored by", name: `Ministry of Culture,` },
  { job: "", name: `Sports and Tourism and Arts Council Korea` },
  { job: "", name: `  This project is a part of 2023 Art Change UP.` },
]

export default function About() {
  const [now, setNow] = useState("Introduction")
  const [idx, setIdx] = useState<number>(0)

  return (
    <div>
      <div className="px-5 pt-5 sm:flex">
        <div className="mb-8 mr-5">
          <div className="text-[17px] w-[310px] h-full sm:fixed px-2">
            <hr className="border-[#bbbbbb]" />
            {aboutMenus.map(({ kor, eng }, idx) => {
              return (
                <div
                  key={kor}
                  onClick={() => {
                    setNow(eng)
                    setIdx(idx)
                  }}
                  className={
                    eng === now
                      ? "text-white cursor-pointer"
                      : "text-[#8A8A8A] cursor-pointer"
                  }
                >
                  <div className="flex my-3 font-medium">
                    <div className="w-1/2">{kor}</div>
                    <div className="w-1/2">{eng}</div>
                  </div>
                  <hr className="border-[#bbbbbb]" />
                </div>
              )
            })}
          </div>
          <div className="w-[310px] h-full bg-black"></div>
        </div>
        <div className="grid grid-cols-10 gap-10 mt-3 sm:px-5 leading-[26px]">
          <div className="text-[15px] xl:col-span-5 col-span-9 sm:col-span-10">
            {parse(aboutMenus[idx].korIntro)}
            {aboutMenus[idx].korIntro ? null : (
              <div>
                {korCredit.map(({ job, name }) => {
                  return (
                    <div className="grid w-full grid-cols-10" key={job}>
                      <div className="col-span-5 sm:col-span-3">{job}</div>
                      <div className="col-span-5 sm:col-span-7">{name}</div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="xl:col-span-5 text-[15px] col-span-9 sm:col-span-10">
            {parse(aboutMenus[idx].engIntro)}
            {aboutMenus[idx].korIntro ? null : (
              <div>
                {engCredit.map(({ job, name }) => {
                  return (
                    <div className="grid w-full grid-cols-10" key={job}>
                      <div className="col-span-5 sm:col-span-3">{job}</div>
                      <div className="col-span-5 sm:col-span-7">{name}</div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <Underbar></Underbar>
    </div>
  )
}
