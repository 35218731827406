const TitlePic = ({ name }: { name: string }) => {
  return (
    <div
      className="w-full h-[133px] sm:h-[197px] md:h-[241px] lg:h-[366px] nxl:h-[495px] bg-center border-[#ffffff88] border-[1px] pb-[20px]"
      style={{
        backgroundImage: `url(/assets/interview/${name}.png)`,
        backgroundSize: "50%",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  )
}

const SubTitle = ({ title }: { title: string }) => {
  return (
    <div className="w-full pt-[80px]">
      <hr />
      <p className="pl-[20px] text-[18px] font-normal py-2">{title}</p>
      <hr />
      <br />
    </div>
  )
}

const QandA = ({
  q,
  a,
  a2,
  br,
}: {
  q: string
  a: string
  a2: string
  br: boolean
}) => {
  return (
    <div className="text-[15px] leading-[26px]">
      <p className="text-[#76A396]" style={{ textIndent: "30px" }}>
        {q}
      </p>
      <p>{a}</p>
      <p>{a2}</p>
      {br ? <br /> : null}
    </div>
  )
}

export function Kyoungmin() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="0" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="‘역할’이 떠올라요. 부모님이 맞벌이를 하셔서 제가 중학생 때까지 할머니께서 거의 키워 주신 거나 다름없거든요. 엄마가 많이 도와주셨지만 어쨌거나 저에게는 제2의 엄마, 길러주신 유모 같기도 하고요. 할머니가 가족의 대장이기 때문에 ‘난 대장 밑에서 크는 자식이다’ 이렇게 느꼈죠. 부족장 밑에서 자라는 느낌으로요."
          a2=""
          br={false}
        />
        <QandA
          q="그 단어가 떠오른 이유가 있나요?"
          a="할머니 스타일이 화려했거든요. ‘어딜 가도 격을 차려야 한다’는 느낌. 의복이나 화장법에서 포스가 느껴져요. 집안일이 다 할머니 손에서 비롯되었기 때문에 집 안에서 대장이기도 하셨고요. 그리고 봉고차를 몰고 다니셨어요. 할머니가 40대 중반에 트럭 운전 일을 하면서 운전면허 1종을 따셨거든요. 나중에는 봉고차도 같이 모셨죠. 그래서 가족 여행을 간다고 하면 할머니가 운전하시는 게 기본이었어요. 저는 그런 부분이 어색하지 않았는데 커서 보니까 좀 생소한 상황이더라고요."
          a2=""
          br={true}
        />

        <img src="/assets/interview/0-1.svg" alt="식장" />
        <br />

        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="할머니는 대장이기 때문에 엄청 주도적이고 도전적이고 강단 있으세요. 판단 능력이 엄청 빠르고 전체적으로 강한 느낌. 원리원칙주의자고 본인이 대장이기 때문에 거기서 벗어나면 큰일 나는 슈퍼 J. 특히 정리 정돈에 대해서 굉장히 갈등이 많았죠."
          a2="항상 행동파셨어요. 집안일은 당연하고 가족에게 문제가 생기면 직접 가서 처리하는 스타일. 집 안팎에서 가족에게 발생하는 문제를 누구에게 맡기지 않고 주도적으로 해결하시는 역할이었어요."
          br={false}
        />
        <QandA
          q="할머니와 함께 사셨으니 그런 부분이 뚜렷하게 보였겠네요."
          a="맞아요. 그래서 희생적이라고도 생각했던 것 같아요. 대장인 동시에 한편으로는 전형적인 가부장제 속 할머니의 느낌도 있거든요. 되게 모순적이에요. 할머니가 주도적인 성격이라 본인 인생도 잘 개척하신다고 생각했는데 나중에 돌아보니까 시간과 에너지를 가족을 위해 다 쏟아내셨더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="다른 가족이 떠올리는 할머니의 모습이 ○○님의 생각과 비슷한가요?"
          a="(엄마의 생각을) 잘 모르겠지만 양가감정이 드시는 것 같아요. 어머니의 희생에 감사한 마음으로 이것저것 챙겨드리시곤 하지만 동시에 같은 여성으로서 안타까운 마음이나 답답한 마음도 있으신 것 같아요. 세대 차이가 있으니 어쩔 수 없었다는 걸 이해하더라도 (할머니께서) 더 배우고 도전적으로 살 수 있었을 텐데, 하는 아쉬움?"
          a2=""
          br={false}
        />
        {/* <QandA
          q="어떤 하나의 사건이라기보다는 함께 살면서 자연스럽게 느끼는 부분이었을 것 같네요."
          a="맞아요. 확실히 역할에 차이가 있으니까. 할머니와 엄마는 일촌이니까 서로 기대는 사이기도 하고 저보다 복잡미묘한 관계일 수밖에 없겠죠. 그리고 둘 다 대장 같은 성격이에요. 한 지붕 아래 대장이 둘일 수 없는데. 제 입장에서 할머니는 배려하고 희생적인 분인데 엄마 입장에서는 남의 의견을 수용하지 않고 주도적으로 처리해 버리는 성격이셨죠."
          a2=""
          br={false}
        /> */}

        <SubTitle title="² 기억" />
        <QandA
          q="어렸을 때 집에 돌아오면 할머니가 맞아 주신 기억이 있나요?"
          a="할머니가 학원에 저를 데리고 오고 가고 그랬기 때문에 사실 할머니와 제가 분리된 시간이 거의 없었어요."
          a2=""
          br={false}
        />
        <QandA
          q="할머니와 함께했던 귀갓길은 어땠나요?"
          a="걸어서 30분 정도 걸리는 성당 유치원을 다녔어요. 바로 옆에 있는 수영장도 다녔고요. 갈 때마다 할머니가 데려다주셨던 모습이 기억나요. 언덕길에 지렁이가 많았고 비가 온 다음 날 아스팔트 위에 눌어붙어 있던 걸 불쌍해했죠. 그리고 초등학교 때 조기 교육을 굉장히 많이 받았는데 선수라도 준비할 기세로 태권도, 인라인스케이트까지 그 당시 또래들이 배우던 건 다 배웠어요. 그때도 학원이 끝나면 할머니와 얘기하면서 언덕길을 내려가고 그랬네요. 어릴 적이라 기억이 어렴풋해요."
          a2=""
          br={true}
        />
        <QandA
          q="기억 속 할머니의 방은 어떤가요?"
          a="할머니 방은 항상 안방이고 장롱이 있어요. 장화홍련에 나올 것 같은, 나무로 된 큰 장롱인데 4개 칸이 있고 거기에 바느질 도구나 가위, 할머니의 화려한 코트가 있었어요. 제가 거기 숨었거든요. 그게 제일 기억에 남아요. 할머니의 패션, 바느질이 다 담겨 있잖아요."
          a2=""
          br={false}
        />
        <QandA
          q="장롱 외에 어떤 물건들이 있었나요?"
          a="돌침대에서 할머니와 할아버지가 주무셨고 그사이에 죽부인이 있었어요. 그래서 제가 죽부인 자리에 항상 끼어들었죠. 장롱 옆에는 교자상이 있었어요. 저희 집이 큰집이라 할아버지 쪽 친척들이 다 여기로 모였거든요. 손님이 없더라도 교자상은 언제 필요할지 모르니까 접어서 장롱 옆에 세워두었죠. 그리고 나무로 된 딱딱한 물건들이 많았어요. TV장이나 교자상도, 울퉁불퉁한 마사지기도 모두 나무였고 대나무 죽부인도 그렇고요. (...) 장롱 안에 이불이 엄청 많았어요. 큰집이었기 때문에 솜이불도 있고 여름용 이불도 있고 진짜 많았어요. 지금은 다 정리됐지만 당시에는 왜 저렇게 많나, 전부 나를 위한 건가 했던 기억이 있네요. 이불 옆 칸에는 한복이 있었어요. 할머니가 결혼하셨을 때 입었던 것도 있고 엄마가 입었던 것도 다려서 보자기에 싸 넣어두고 그러셨답니다."
          a2=""
          br={true}
        />

        <img src="/assets/interview/0-2.svg" alt="팔씨름" />
        <br />

        <QandA
          q="할머니집 하면 떠오르는 냄새나 소리가 있나요?"
          a="할머니가 반찬이나 된장찌개를 되게 잘하셨어요. 특히 제가 두부를 무척 좋아해서 두부 반찬을 많이 해주셨어요. 아파트 바로 앞에 두부 가게 아저씨가 트럭을 몰고 오실 때마다 반찬이나 무침, 전골을 만들어 주셨죠. 그리고 김치를 거의 매년 세 번씩 담근 것 같아요."
          a2=""
          br={false}
        />
        <QandA
          q="일 년에 세 번이요? 꽤 많이 하셨네요."
          a="네. 왜냐하면 배추김치도 담그셔야 하고 총각김치도 파김치도 담그셔야 하니까. 김장하고 조금만 지나면 또 김장을 해야 하고. 저도 언제나 동원되었습니다. 또 옛날 어른들은 고기반찬보다는 채소 위주로 많이 드셔서 시장에서 나물 사다가 많이 해주셨어요. 제가 성장하면서 고기가 필요해지니 일부러 고기 요리를 해주셨지만 그 전에는 채식, 거의 절밥 먹고 다녔어요."
          a2=""
          br={false}
        />

        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="할머니집을 떠올릴 때 돌아가고 싶은 어떤 순간이 있나요?"
          a="저희 집은 완전히 개인주의라서 각자 방에서 밥을 먹어요. 사이가 나쁜 게 아니라 각자 편한 걸 존중하다 보니 그렇게 됐어요. 어릴 때는 불편하든 말든 각자 밥 먹을 수 있을 만큼 방이 없으니까 부엌 식탁에서 단란하게 먹었는데, 별 얘기 안 했지만 같이 찌개 퍼서 먹었던 순간들이 좀 그립네요. 그때는 복작복작 먹어야 했고 친척들과 교류도 많았어요. 할머니는 대장이니까 친척들이 오면 거실에서 온 가족과 대화하고 다과도 함께 먹고 그랬는데 이런 것들에 향수가 남아요. 지금은 밥을 같이 먹는다고 해서 그렇게 즐겁지는 않거든요."
          a2=""
          br={false}
        />
        <QandA
          q="클수록 집에서 보내는 시간이 확연히 줄잖아요."
          a="중학교 가면서 많이 바뀌었어요. 학원도 가고 야자도 하고 친구랑도 놀아야 하니까 가족들과 보내는 시간이 크게 줄었는데 사실 할머니도 속이 시원하셨을 것 같아요. 누구보다 독립적이고 싶었던 분인데 양육자로서 누군가를 계속 챙겨야 했으니까. 이제는 같이 밥 먹자고 해도 드라마 봐야 한다고 혼자 드세요. 그게 할머니의 하루 루틴이고 낙인데 제가 그걸 방해할 수는 없죠."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 함께한 기억 중에 인상 깊은 것이 있다면 말씀해 주세요."
          a="할머니는 항상 사진을 찍으셨어요. 사진 찍는 거 여전히 좋아하셔서 최신 폰 쓰시고 유튜브도 다운받고 난리였는데, 언제나 신세대 스타일을 좋아하셨어요. 여행 가거나 놀러 갈 때 항상 저랑 사촌 동생들을 찍어주시고. 애들도 챙기면서 진두지휘하는, 유치원 원장님 느낌. 옛날 일이라 사진 남아 있는 게 없을 줄 알았는데 엄마가 모아두셔서 앨범이 엄청 많더라고요. 그 당시에는 다 필름 카메라로 찍고 인화하잖아요. 필름을 사진으로 남기고 앨범에 보관해 놔서 그 때의 기억이 고스란히 남은 것 같아요. 보면서 기억을 조금씩 되새길 수도 있고요. 그러지 않았다면 아예 기억 못 했을 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 함께했던 기억들이 현재 본인에게 어떤 영향을 끼쳤나요?"
          a="(기억들이) 끼친 영향이 크긴 하지만 저는 할머니를 보면서 오히려 반대로 살아야겠다고 생각했었어요. 저렇게까지 ‘틀’을 갖고 살고 싶지 않아서요. 할머니는 전통적인 것, 전형적인 것을 지키려 노력하시느라 본인이 하고 싶은 공부나 회사 생활을 거의 못 하셨어요. 세대 차이겠죠. 저의 길을 만들려면 할머니처럼 틀을 고수하기보다는 부수면서 살아야겠다고 생각했던 것 같아요. 
물론 할머니께 배운 점도 많죠. 저희 할머니가 3년 전에 사설 학교를 졸업하셨거든요. 전쟁 때문에 학교 못 나온 분들의 교육과 학위 취득을 돕는 고등학교에 다니면서 졸업까지 하셨어요. 구청에서 학위 수여식을 했을 때 제가 사진을 찍으러 갔었는데, 무언가를 끊임없이 배우려는 태도는 할머니께 언제나 많이 배우는 것 같아요."
          a2=""
          br={true}
        />

        <img src="/assets/interview/0-3.svg" alt="튤립" />

        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집을 떠올렸을 때 꺼림칙한 공간이나 물건이 있을까요?"
          a="저희 집은 큰집이라 족보가 진짜 많았어요. 지금은 한 권만 두고 다 버렸지만 예전에는 몇십 권이 있었던 것 같아요. 그 사실이 불편한 이유는 그것들이 결국 큰집 엄마, 큰집 며느리였던 할머니의 희생으로 이루어졌다는 느낌이 들기 때문이죠. 나중에 알게 된 사실인데, 할머니집 근처에 사시던 친척들이 밥을 먹으러 (집을) 수시로 들락거렸다는 거예요. 할머니는 항상 밥을 차려줘야 하는 입장이었던 거죠. 불특정 다수가 언제 들이닥칠지 모르는 환경에서 계속 식사를 차려야 했으니 여가 시간이 거의 없었죠. 그래서 족보를 볼 때마다 마음이 얼마나 안 좋았을까 하는 생각이 들어서 별로 안 좋아했어요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니가 중요하게 여기셨던 금기나 규칙 혹은 주의사항이 있었나요?"
          a="한복과 패물은 건드리지 말 것. 할머니의 추억 상자는 건드리면 안 됐어요. 되게 소중한 거였나 봐요. 그리고 여느 할머니집처럼 직접 키우시는 식물이 많은데 이것도 절대 건드리면 안 돼요. 물도 주면 안 돼요. (할머니가 물 주시는) 주기가 바뀌어 버리니까."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에 대한 본인과 가족들의 기억이 서로 달랐던 적이 있나요? "
          a="어렸을 때는 큰집이라서 친척들이 자주 오는 거라고 생각했는데, 커서 보니 살던 동네 자체가 집성촌이더라고요. 할머니는 단순히 우리 집의 대장이 아니라 일가의 대장이셨던 거죠. 지금도 형수님 하면서 찾아오시는 분들이 계시고, 여전히 집안에서 큰 누나의 위치세요. 할머니가 불규칙한 생활을 싫어하시고 밥 먹는 시간과 빨래 개는 방법 같은 것들에 엄격하셨던 이유가 그런 기준을 잘 지키지 않는 사람들을 너무 많이 봐 오셨기 때문이라는 걸 알게 됐어요. 여가 시간도 없고 시간에 쫓기시면서 할머니만의 규칙이 생기셨죠. ‘할아버지가 못된 분도 아니셨고, 할머니와 함께 잘 사셨는데도 어떻게 희생을 강요하는 분위기가 계속 남아 있었지?’ 생각했었지만 이제 보니 잘못된 문화가 자리잡혀 있었던 거였어요. 할머니도 의심하지 않았고 할아버지도 그러려니 했던 문화요. 크니까 보이더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="외할머니와의 기억이 많으신데 혹시 친할머니에 대한 기억도 있나요?"
          a="친할머니와는 함께 살지 않기 때문인지 명절에만 찾아뵙는데 안마 열심히 해 드리고 덕담 나누는 게 (교류의) 전부예요. 할머니집에 가더라도 오래 머물고 싶지 않고요. 누가 강제한 것도 아닌데 그 정도의 시간만 공유하다 보니 자연스럽게 그런 사이로 남은 것 같네요."
          a2=""
          br={true}
        />
        <QandA
          q="친할머니의 집을 떠올릴 때 기억나는 공간이 있나요?"
          a="할머니 방과 빈방이 하나씩 있고 빈방에는 김치냉장고만 있었어요. 누군가 머물진 않으니까 난방이 되지 않아 온기도 없이 썰렁하고, 반찬을 많이 만들어도 가져갈 사람이 없으니 (남은 것을) 냉장고에 넣어뒀죠. 가까이하고 싶지만 썰렁한 느낌이 드는 게 어쩌면 친할머니랑 비슷하게 느껴졌어요. 쉽게 다가가기 힘든, 그런 차가운 느낌."
          a2=""
          br={false}
        />
        <QandA
          q="다른 공간들은 어땠어요?"
          a="전체적으로 누런 장판이 깔려 있었어요. 그리고 원래 하얬지만 노리끼리하게 변한 벽지. 할머니 한 분만 계셔서 그런지 안방에 있는 장롱도 크지 않았고 1인 침대와 나무로 된 옷걸이가 있었어요. 그리고 항상 거실 소파에 (할머니가) 앉아 계셨어요. 다리가 아프니까 소파에 앉아서 바깥 풍경이나 키우는 식물을 보셨던 것 같아요. 화장실에도 물건이 많지 않고 샴푸 딱 하나만 있었어요. 빈 공간 뿐이고 뭐든 하나씩만 있으니까 (공간이) 혼자 쓰기에 너무 넓은 느낌이었죠. 욕조와 변기가 있는 평범한 화장실인데 어쩐지 쓸쓸했어요."
          a2=""
          br={false}
        />
        <QandA
          q="쓸쓸하다고 생각한 이유가 있을까요?"
          a="보통 친구들은 1인 가구더라도 두고두고 쓸 물건을 미리 사두거든요. 그런데 할머니는 남겨진 인생이 별로 없다는 느낌, ‘앞으로’ 보다는 ‘지금’ 쓸 것만 사시는 느낌이었어요. 확대 해석일 수도 있는데 ‘얼마나 살겠어’하는 태도가 보였던 것 같아요. 10년 동안 한 해에 한 번씩 갔었는데 바뀐 게 거의 없더라고요. 추가되는 물건도 별로 없고. 그게 좀 슬프더라고요 저는."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="미디어에서 밥을 상다리 부러질 듯이 엄청 차려주고 손자 손녀 예쁘다 해주는, 그런 항상 다정한 이미지의 할머니. 아니면 시어머니 역할로 나와 엄마를 괴롭히는 악독한 할머니 이미지가 떠오르네요."
          a2=""
          br={true}
        />
        <QandA
          q="그런 이미지가 ○○님의 할머니를 떠올릴 때 영향을 미치기도 하나요?"
          a="저는 두 분 다 그렇지 않았어요. 그래서 그런 타자화된 (전형적인) 이미지를 기대하게 되는 것 같아요. 사실 할머니도 한 명의 인격체인데 제가 그런 역할을 기대하게 돼요. 더 응석 부리게 되고 더 다정하기를 바라게 되죠. 그래서 약간 아쉬움을 갖기도 하고요."
          a2=""
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화에 대한 소감이 있다면."
          a="친할머니에게 조금 빚진 마음이 있었다는 걸 알게 됐어요. 사람으로서 대화도 하고 시간을 보내고 싶다는 생각을 했고요. 외할머니는 같이 사니까 밥을 좀 함께 먹어야겠다 싶네요. 사실 할머니께서 당신의 생각을 제게 강요한다는 부정적인 인식이 좀 있었어요. 할머니의 행동을 구성하는 배경을 알고 있었지만 그걸 굳이 연결 짓지 않았는데 오늘 말하면서 이유를 깨닫게 됐고 저도 배려해야겠다는 생각이 들어요. 일방적으로 맞춰주는 관계가 아니라 같이 맞춰가는 관계라고 생각했어야 하는데 너무 어렸을 때부터 붙어 있다 보니 분리해서 생각하지 않았던 것 같네요."
          a2=""
          br={true}
        />
      </div>
    </div>
  )
}

export function Minkyoung() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="1" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="저는 할머니 하면 바로 할머니집이 떠올라요. 어렸을 때 자주 갔기 때문에 제 어린 시절도 통째로 함께 떠오르고. 구체적으로 어떤 물건을 생각해 보자면 염주와 불경이요. 할머니가 저녁마다 불경을 외우고 염주를 굴리시면서 기도를 한두 시간씩 하셨거든요. 할머니가 돌아가신 이후로 염주는 제가 유품으로 가지고 있고 불경은 본가에 있습니다."
          a2=""
          br={false}
        />
        <QandA
          q="기도드리던 할머니의 모습이 기억에 남으셨다는 거죠?"
          a="네. 저랑 여동생은 밤마다 기도하는 할머니를 지켜봤던 입장이라서. 기억나는 장면을 얘기해 보자면 밤 아홉 시쯤 될 때 할머니가 TV 소리 낮추라고 말씀하셨어요. 그러면 음량을 1이나 2 정도로 두고. 할머니는 사전만큼 두꺼운 팥색 표지의 불경을 펼치시고. 단 한 글자도 해독이 불가능한 암호문 같은 불경이었는데 할머니가 그걸 다 외우고 계셨어요. 눈도 감은 채로 한참을 읊으셨죠. 하도 들어서 저도 한 페이지는 외웠을 정도로. 기억하기로는 신묘장군대다라니경의 일부였는데, “사라사라 시리시리 소로소로 못쟈못쟈 모다야 모다야…” 뭐 이런 구절. 도저히 외울 수가 없는 것들인데 어떻게 외웠지 아직도 의문이고. 그렇게 기도가 끝나고 나면 이번에는 염주를 꺼내 한 알을 굴릴 때마다 한 번씩 “관세음보살” 하는 두 번째 기도를 하셨어요. 염주 알이 108개거든요. 염주를 매일 10바퀴씩 돌리셨어요. 그러니까 1080번이나 기도하시는 거죠. 그걸 다 하면 한두 시간 정도가 지나요. 끝나면 저랑 동생은 다시 TV 음량을 높이고 불을 끄고 야간 등을 켜고 잘 준비를 해요. 그게 할머니와 우리 자매의 나이트 루틴이자 리추얼이었어요. 옛날에는 주말마다 할머니집에 가서 자고 오고 그랬으니까 매주 본 거죠. 그래서 그 모습이 기억에 제일 남아요."
          a2=""
          br={true}
        />
        <QandA
          q="그럼 할머니집에 관한 기억이 형성된 시기가 주로 어린 시절인가요?"
          a="유치원 다닐 때부터 초등학교 고학년 때까지 할머니집에 자주 갔기 때문에 그 때의 기억이 거의 연결되어 있어요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 정서적으로 친밀한 편이셨나요?"
          a="할머니가 돌아가시고 나서는 관계에 발전이 없지만 떠올려 보면 무척 친밀한 편이었죠. 할머니가 편했고 또 많이 의지했으니까요. 그런데 한편으로 제가 할머니와 ‘친하다’고 할 수 있는지는 잘 모르겠어요. 제가 당시에 너무 어리기도 했고 또 일방적으로 양육받는 입장이었거든요. 그래도 일반적인 의미에서는 친밀한 사이였어요. 할머니도 무척 반겨주셨고. 못 뵌지 몇 년이 지난 지금 느끼는 감정은 사람 대 사람으로 느끼는 친밀감 보다는 아득하게 그리운 감각에 가까운 것 같아요. 노스탤지어 그 자체. 영원히 돌아갈 수 없는 어떤 순간에 대한 모호한 갈망이나."
          a2=""
          br={true}
        />
        <QandA
          q="할머니는 어떤 성격이셨나요?"
          a="저는 할머니가 무척 선하고 순한 분이라고 생각했었어요. 남에게 싫은 소리를 대놓고 하시는 일이 거의 없으셨고 인망도 좋으신 데다 저희 자매에게 꾸중도 거의 안 하셨거든요. 뭘 하지 말라는 금기나 제한이 거의 없었고. 가족을 위해서 희생하시는 분이라고 생각했죠. 이것저것 챙겨 주려 하시고 배려하시는 성격이셔서. 그런데 마냥 그런 것만은 아니었어요. 환경이나 상황 때문에 억눌려 있던 부분도 있지 않을까 생각해요. 욕심이나 성격 자체가 없었던 건 아니었죠."
          a2=""
          br={false}
        />
        <QandA
          q="그렇게 생각하게 된 계기가 있었나요?"
          a="제가 자라 머리가 굵어진 뒤에 생각해 보니 그랬구나 싶기도 했고, 또 할머니가 자주 해 주시던 옛날이야기들이 있어요. 돌아가시기 전에 치매가 와 기억이 흐려지고 난 뒤에도 계속 반복하실 정도로 깊게 새겨진 할머니의 기억들이요. 듣다 보면 당신의 성격이 그렇게 형성될 수밖에 없었던 이유를 알 것 같더라고요. 마음에 맺힌 것도 많고, 우리처럼 자유로운 삶을 사신 게 아니니까."
          a2=""
          br={false}
        />
        <QandA
          q="어떤 이야기였는지 말씀해 주실 수 있나요?"
          a="이야기가 너무 많아서 다 세세히 얘기하긴 힘들지만요. 아들이 최고인 시대에 딸이 많은 집의 장녀로 태어나셨고, 일제강점기를 겪으시고, 학년을 월반할 정도로 영특하셨는데 여자라 학교를 포기하신 게 못내 가슴에 맺혀 계시고, 시집 가신 후에는 아들을 낳지 못해 모진 구박을 견디시고, 또 전쟁을 겪으시고, 떡집을 운영하며 많은 가족을 부양하시고… 한 사람의 인생에서 전부 벌어졌다기에는 너무 벅찬 사건들이죠. 할머니가 언제나 선한 태도를 유지하려 하셨던 게 제일 대단하게 느껴질 정도로."
          a2=""
          br={true}
        />
        <QandA
          q="할머니에 대한 본인과 다른 가족들의 기억에 차이가 있는 것 같나요?"
          a="사실 큰 차이는 없는 것 같아요. 가족들, 특히 부모님은 기억이 선명할 때 할머니와 오래 함께했으니 좀 더 현실적인 기억을 가지고 있지 않을까요? 그냥 전반적으로 배려심이 있고 착한 분으로 기억하고 있어요. 또 희생적이셨죠. 할아버지가 고생을 많이 시키셨다고 들었어요. 그래도 할머니는 제가 아는 한 언제나 먹을 음식이 생기면 빈 밥상에 돌아가신 할아버지 몫을 따로 떼어 놔두었다가 드셨어요. 전 그런 제사 의식을 영 좋아하지 않았지만 그 행위가 할머니가 고수해 오셨던 삶의 태도와 가치를 잘 대변한다고 생각했던 것 같네요."
          a2="아, 그런데 그건 좀 충격이었어요. 할머니집 서랍에서 할머니 해외여행 가셨던 사진 발견한 거. 왜냐면 저희 엄마 아빠 동생 모두 해외여행을 가 본 적이 없거든요. 그런데 정작 할머니가 동남아에 불교 여행 가셨던 사진 있는 게 놀라웠어요. 심지어 코끼리를 만지고 계시더라고요. 제가 할머니 인생을 너무 재미없게만 생각했던 건지도 몰라요. 하긴 저는 27년 살고 할머니는 90년을 넘게 사셨는데 제가 뭘 알겠어요. "
          br={false}
        />
        <SubTitle title="² 기억" />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="이사를 한 번 해서 기억하는 할머니집은 두 개인데 그중에서 첫 번째 집에 대해서 얘기할게요. 저는 고향이 포항이라 거기서 자랐는데 할머니집도 포항에 있어 가까웠어요. 차로 20분 정도? 아빠 차를 타고 가던 그 길의 형태나 분위기가 생생하게 기억나요. 할머니집은 구시가지 길목에 있었고 대문은 아주 낡아 칠이 벗겨지고 군데군데 녹슬고 구부러진 녹색 철문이었어요. 앞에 ‘사글세’ 글씨가 붙어 있고. 열 때마다 끽끽거리는 소리가 나는 문을 몸으로 부딪쳐서 열고 들어가면 작은 뒷마당이 나왔는데, 콘크리트 벽돌로 만든 화단에 할머니가 키우는 수세미 같은 앙상한 식물들이 있고, 왼쪽에는 사글세(월세)방들 서너 개가 주르르 있었어요. 초가지붕과 슬레이트가 섞여 있었는데 저는 어려서 거기 사는 분들이 세입자라는 걸 몰랐고 할머니집 가는 길에 있는 일종의 게임 NPC처럼 생각했던 것 같아요. 죄송하네요. 어쨌든 그분들께 인사하면서 할머니집 뒷문을 열면 옛날 집 구조라 특이하게 타일로 마감된 수돗가 겸 부엌이 나왔어요. 거기서 “할머니!” 하고 외치면 할머니가 미닫이문을 열고 반가운 얼굴로 나오셨죠."
          a2="아빠가 주신 할머니집 뒷문 사진이 있는데 보여드릴게요. 이사할 때 사진이라 물건이 널려 있어서 엉망이지만 그래도 분위기가 생생하네요."
          br={true}
        />
        <img src="/assets/interview/1-1.svg" alt="가는 길" />
        <br />
        <QandA
          q="할머니집을 떠올렸을 때 기억에 남는 방이나 공간이 있나요?"
          a="할머니집 자체가 특이한 공간이었어요. 옛날에 떡집이었다고 그랬거든요. 중앙에 커다란 거실 겸 생활 공간이 있고 그 주위를 부엌이나 골방 같은 부수적인 것들이 둘러싸는 구조였어요. 특히 기억에 남는 건 출입구가 하나인 일반적인 집 구조와 다르게 앞문과 뒷문이 따로 있었는데, 각각 완전히 다른 방향의 길로 이어졌다는 거예요. 어느 문으로 나가느냐에 따라 바깥세상의 분위기나 생김새가 전혀 달랐거든요. 뒷문으로 나가면 어쩐지 쓸쓸한 느낌이 감돌고 길도 좀 무서웠는데 앞문으로 나가면 꽃이 만발해 있고 옆집 할머니가 활기차게 반겨주셨어요. 그래서 앞문과 뒷문을 연결하는 집 전체가 하나의 거대한 순간이동 포탈 같았죠."
          a2=""
          br={true}
        />
        <QandA
          q="전체적인 인테리어는 어땠나요?"
          a="옛날 집 하면 생각나는 전형적인 요소들이 많았어요. 노란 장판에 벽 한 면을 다 채우는 큰 자개 옷장이 있었고 거의 다 좌식 가구였죠. 커다란 정사각형 옥장판 위에서 생활하고 여름밤이 되면 그 위에 모기장도 쳤어요. 큰 방 하나에 가구들은 모두 벽에 붙어 있고 빈 공간이 많아서 저랑 동생이 빈 공간을 엄청 뛰어다녔던 기억이 나네요. 골방도 하나 있었는데 아빠가 어렸을 때 썼던 방인 것 같아요. 보일러가 안 들어서 되게 추웠지만."
          a2=""
          br={true}
        />
        <QandA
          q="인터뷰 초반에 할머니를 생각하면 염주와 불경이 떠오른다고 하셨는데, 할머니집을 떠올리면 생각나는 다른 물건이 있을까요?"
          a="네. 할머니집 하면 자개 가구가 떠올라요. 제가 할머니집에 있던 자개 문갑과 화장대를 옛날부터 무척 좋아해서 반쯤 농담삼아 제게 남겨달라 말씀 드렸어요. 그런데 할머니가 돌아가시기 전에 아빠가 그 가구들을 처분하시면서 저한테 미리 주셨어요. 유품은 아니었지만 지금은 그렇게 된 셈이죠. 할머니가 젊으셨을 적부터 쓰시던 가구를 제가 계속 간직할 수 있다는 게 좋아요. 가능한 한 오래 쓰고 싶어요."
          a2="또 생각나는 건 커다란 전축인데요. 엄청 컸어요. 제 덩치의 두 배쯤 될 정도로 육중하고 소리도 정말 컸는데 요란하고 경박한 뽕짝만 나왔거든요. 할머니가 그렇게 활달한 분이 아니셨는데 가끔 그걸로 뽕짝을 틀고 저와 동생과 함께 왈츠를 췄어요. 아는 춤도 없고 그냥 몸만 흔드는 거죠. 전형적인 왈츠 동작처럼 손을 맞잡고 빙글빙글 돌며 춤을 췄던 것 같아요. 모든 게 이상했는데 무척 즐겁고 신났던 기억이 나요."
          br={true}
        />
        <QandA
          q="아주 강렬했던 기억도 있을까요?"
          a="강렬한 기억이 하나 있어요. 할머니집 골방에 고모들과 아빠가 쓰던 물건 중에 옛날 인형이 있었거든요. 지금은 거의 만들지 않는, 눕히면 눈꺼풀이 감기고 앉히면 열리는 그런 사람 모양의 인형이었어요. 얼굴은 플라스틱인데 몸은 천으로 된 군데군데 곰팡이가 핀 저주받은 것처럼 생긴 낡은 인형이요. 저는 어리고 무서운 걸 잘 모를 때라 그 인형을 종종 가지고 놀았는데 할머니가 이사하시면서 그 인형도 버렸어요. 이사 후에 그 집을 반쯤 허물게 됐는데 이사 간 집이 근처였어요. 그래서 동생과 귀신의 집에 가는 기분으로 공사 중인 예전 할머니집에 놀러 갔었죠. 갔더니 장판도 벽도 다 뜯겨 있고 텅 빈 거실에 문짝만 덩그러니 놓여 있는데 그 밑에 인형이 목만 깔려 있는 거에요. 원래 골방에 있던 건데. 목이 깔린 채로 인형 얼굴이 정확히 우리를 향해 있었어요. 짐이 복잡하게 놓인 것도 아닌데. 텅 빈 바닥에 그 인형만 덩그러니. 문틀에서 빼낸 문짝에 목만 정확하게 눌린 채로. 그 당시에는 별로 무섭지도 않았고 동생과 호들갑 떨면서 나왔는데 지금 생각해 보니 왜 그랬는지 영문을 모르겠네요."
          a2=""
          br={false}
        />
        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="어릴 적 갔던 할머니집에 지금도 방문하실 수 있나요?"
          a="지금은 갈 수가 없어요. 아빠가 처분하셔서 집 주인이 바뀌었거든요. 그 주변은 언제든지 갈 수 있지만 제가 서울에 살아서 가지 않은지도 한참 됐고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올렸을 때 생각나는 냄새나 소리가 있나요?"
          a="할머니가 돌아가신 후 할머니집에서 가져온 물건들 중에 낡은 반짇고리가 있어요. 할머니가 그 안에 나프탈렌을 넣어두셨어서 지금은 들어 있는 물건이 없는데도 나프탈렌 냄새가 나요. 그게 전형적인 할머니집 냄새인 것 같아요.  제 친구도 그 냄새를 맡자마자 할머니집 냄새가 난다고 하더라고요. 나프탈렌이 발암물질로 밝혀졌다는 얘기를 들은 적이 있긴 하지만… 어쨌거나 가끔 생각나면 반짇고리를 열고 냄새를 들이마시기도 합니다. 그 친구도 저희 집에 놀러 올 때 냄새를 맡고 가요. 좀 이상한가요?"
          a2=""
          br={true}
        />
        <img src="/assets/interview/1-2.svg" alt="반짇고리 엶" />
        <img src="/assets/interview/1-3.svg" alt="반짇고리 닫음" />
        <br />
        <QandA
          q="할머니집을 떠올리게 하는 특정한 물건이 있을까요?"
          a="저는 불교용품을 보면 할머니집이 떠올라요. 할머니가 불교에 많이 의지하셨고 저희 집도 불교 집안에 가까워서 그런 것 같아요. 할머니와 함께 절에 갔던 기억도 많고요."
          a2=""
          br={true}
        />
        <QandA
          q="그 순간으로 돌아가고 싶은 할머니집에서의 기억이 있나요?"
          a="돌아가고 싶다고는 거의 생각하지 않아요. 할머니집에 있을 때는 무척 행복했지만 어린 시절의 제 인생이 지금보다 낫다고 생각하지 않거든요. 문득 그리운 감정이 드는 것뿐이죠. 할머니가 불경을 외우던 밤에 동생과 선풍기 틀고 이불 속에 들어가서 조용히 발장난치고 TV에서는 아무 의미 없는 보험 광고가 반복해서 흘러나오고 밖은 좀 쌀쌀한 늦여름이고. 이런 기억이 그립기는 해요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에서의 경험들이 본인에게 어떤 영향을 끼친 것 같나요?"
          a="저는 굉장히 큰 영향을 끼쳤다고 봐요. 제가 거의 주말마다 할머니집에 갔는데, 집에서는 꽤 엄격하게 자랐지만 할머니는 저희를 무척 오냐오냐하셨거든요. 특별히 어떤 영향이었다고 말하기는 어렵지만 어린 시절에 할머니집에 자주 가지 않았더라면 자란 환경 자체가 달랐을 테고 지금의 제가 되지는 않았을 것 같네요. 할머니집은 저한테 도피처였고 집에 문제가 생기거나 버티기 어려워졌을 때 문제를 외면한 채 그냥 훌쩍 떠나 버릴 수 있는 공간이었거든요. 저뿐만 아니라 가족 모두에게 그랬던 것 같아요. 어떤 임계점을 넘지 않도록 도와주는 곳. 하지만 할머니 손에서만 자랐다면 저는 엉망진창이었겠죠 아마도?"
          a2=""
          br={false}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집을 떠올렸을 때 꺼려졌던 공간이나 물건이 있나요?"
          a="지금 생각하면 모든 게 어딘가 꺼림칙한 구석이 있었던 것 같아요. 특히 할머니집 구석의 골방은 아무도 들어가지 말라고 한 적 없는데 괜히 두려워했어요. 한 구석에 고모와 아빠가 썼던 물건들이 쌓여 있었거든요, 창고처럼. 내 것이 아닌 옛날 물건들이 좀 무서웠어요. 그리고 수세식 화장실이 따로 없어서 플라스틱 통 하나를 요강으로 썼는데 그걸 쓰려면 밤에 혼자 부엌까지 나와야 했어요. 옛날 집이라 바퀴벌레가 많아서 문을 열면 어둠 속에서 무언가 사사삭 벽을 기어갔거든요. 엄청 깜깜한 곳에 냉장고 불빛만 깜빡거리던 모습이 을씨년스러워서 화장실 가는 걸 무서워했던 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에서 특별히 금지된 행동이나 규칙이 있었나요?"
          a="금기가 없었어요. 그래서 동생과 저는 금기를 자꾸 만들었어요. 아무도 뭘 하지 말아야 한다고 한 적이 없는데 금기를 만들어서 놀았거든요. 둘 다 길치라서 할머니집 일정 반경 밖으로 나가지 않다가도 매일 탐험하듯이 한 블록씩 더 걸었어요. 또 주변 민가 중에 무섭게 생긴 집이 있으면 폐가 취급하기도 했죠. 사람 사는 집인데도. 골방도 금지되지 않았는데 금지된 방처럼 여기면서 동생과 몰래 다녀오곤 했어요. 물건을 그 안에다 숨기고 놀고."
          a2="아, 그런데 금기가 하나 있었죠. 할머니 기도하실 때 동생과 둘 다 조용히 있는 거. 그땐 시끄러운 소리 내면 할머니가 살짝 흘겨보시면서 쉿 하고 주의를 주셨어요. 그게 유일한 금기였네요. 심지어 종교적인 데다 엄숙하고."
          br={true}
        />
        <QandA
          q="할머니집에 대한 본인과 가족들의 기억이 다른 경우가 있었나요?"
          a="달랐던 기억은 아닌데 특이한 점이 있었어요. 저에게 할머니집은 그냥 할머니집이거든요. 원래 좀 어딘가 이상하고 텅 빈 곳이라 여겼는데 가만히 생각해 보니 여기서 고모와 아빠가 자란 거예요. 저한테는 어린 시절에 잠시 머무르고 떠나던 공간이라 무언가 자라거나 인생이 흘러가는 공간이 아니라 쉬거나 멈추는 장소였거든요. 그래서 골방에 있던 아빠 졸업 사진이나 필기가 있는 토익책 같은 것들이 무서웠어요. 그곳이 삶의 공간이었다는 게 이상했어요."
          a2="두 번째로 저희 할머니가 제가 태어나기 전에 떡집을 하셨다고 해요. 그런데 저는 한 번도 본 적이 없거든요. 할머니 손을 잡고 시장에 가면 사람들이 저를 떡집 손녀라고 불러요. 한 번도 저희 집에서 떡 만드는 걸 본 적이 없는데. 떡을 좋아한 적도 떡을 만든 적도 없는데 떡집 손녀라고 불린다는 게 이상했죠."
          br={true}
        />
        <QandA
          q="그런 사실을 알게 된 이후에 ○○님의 할머니집에 대한 생각이나 인식이 바뀌기도 하셨나요? "
          a="글쎄요. 달라졌다면 달라졌다고 할 수 있을 것 같네요. 할머니집을 새롭게 보게 된 거니까요. 하지만 크게 바뀐 건 없어요. 저한테 할머니집은 어렸을 때부터 언제나 이상하고 신기한 공간이었거든요. 마냥 편안하고 따뜻한 곳은 아니었어요. 그래서 할머니집이 낯설게 느껴질 때도 그게 할머니집의 수많은 모습 중의 하나라고 생각했던 것 같아요."
          a2="한편으로는 저한테 할머니집이 무슨 의미였는지, 할머니집 혹은 할머니와 제가 무슨 관계인지도 자주 고민했죠. 그러다 보니 할머니집보다는 오히려 제 자신에 대한 생각이 바뀐 것 같아요. 저는 어린 시절을 할머니집에서 보낸 할머니집으로부터 온 사람인데, 점점 그곳으로 갈 수 없게 되면서 어렸을 때의 나와는 다른, 돌이킬 수 없는 지점을 넘은 사람이 된 듯한 감각이 들어요. 그게 이상해요."
          br={false}
        />
        <p className="text-[15px] leading-[26px]">
          또 할머니집에서 물리적으로나 정신적으로나 멀어지는 게 제게 좋을지
          나쁠지 생각해 본 적이 있어요. 필요하지만 슬픈 일인 것 같아요.
          할머니집은 저한테 도망치는 곳인데 이제 그곳이 없거든요. ‘이제는 더
          이상 물러날 곳이 없는’ 상태인 거죠. 저에게 집은 도망치는 곳에서
          책임져야 할 곳으로 바뀌고 있고 그게 응당한 삶의 방향이겠지만
          한편으로는 쓸쓸하더라고요. 할머니집이 그리워도 돌아가고 싶다는 생각을
          한 적은 없는데 문득 삶이 너무 힘들어지면 그런 마음이 들지 않을까요?
        </p>
        <br />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="저희 할머니는 미디어에서 주로 보여주는 전형적인 희생적인 할머니의 이미지에 부합하는 분이셨던 것 같아요. 그래서 저는 할머니라는 인물이 납작해지는 게 싫어서 의외의 면을 찾으려고 의식적으로 노력했었네요. 그런 작업을 되게 재밌어했던 것 같아요. 언제나."
          a2=""
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화에 대한 소감이 있다면."
          a="저는 어린 시절에 대해 생각을 많이 하는 사람이기도 하고 이 프로젝트의 주제 자체가 재미있었습니다. 그리고 저를 돌아보는 데 도움도 됐네요. 할머니가 보고 싶어요. "
          a2=""
          br={false}
        />
      </div>
    </div>
  )
}

export function Lin() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="2" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="저도 눈이 동그랗게 생겼는데 할머니 눈이 되게 동그래서 소가 생각나요. 옛날에 할머니가 집에서 키우던 소가 끌려가서 송아지가 하루 종일 울었다고 얘기했었던 적이 있었는데 … 모르겠어요. 그 얘기를 했던 할머니와 소의 눈을 닮은 할머니의 눈이 제일 먼저 떠오르는 것 같고. 그 다음으로는 얼른 전기장판에 누워서 같이 그냥 푹 자고 싶다(는 생각.)"
          a2=""
          br={true}
        />
        <QandA
          q="평소 할머니와 시간을 자주 보내시나요?"
          a="같이 있는 시간 자체는 어렸을 때 빼고는 그렇게 많지 않은 것 같아요. 집은 엄청 가깝거든요. 자전거 타면 8분 정도 걸리고. 예전에는 할머니랑 같이 살았어요. 어렸을 때는 옆에 딱 붙어서 할머니를 만지면서 잤고요. 얼마 전에 되게 피곤했을 때 이른 시간부터 할머니와 함께 내리 잤는데 그 잠이 제 인생 최고의 잠인 것 같다는 생각이 들 정도로 그 순간 자체가 꿈 같았어요. 완전히 자 버려서 아예 세상에서 사라진 느낌이었어요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와의 추억을 생각하면 어떤 시기가 떠오르세요?"
          a="그때그때 다르긴 한데 요즘에는 최근 기억을 많이 떠올리는 것 같아요. 병원에 있을 때라서 그런지. 제가 간병할 수 있는 시간이 있어서 작년 10월쯤에는 병원에서 같이 시간을 보냈거든요. 아니면 2~3년 전에 그냥 동네 걸어서 마트 갔던 거. ‘장군마트’라고 집 근처에 슈퍼가 있어서 거기 가고 그랬었는데. 옛날에는 안 그랬는데 이제는 다른 할머니들 보면 걸어 다니시는 것만으로도 되게 부럽다, 저 할머니들은 우리 할머니보다 연세도 많이 드신 것 같은데 산책도 하시네 그런 생각이 들어요. 좀 부럽더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 각별하신 것 같은데 정서적으로 얼마나 가깝다고 느끼세요?"
          a="얼마 전에 친구의 고민을 들어주러 간 자리가 있었어요. 제가 원래 그런 적이 없는데 친구 고민이 전혀 들리지를 않는 거예요, 계속 할머니 생각만 나서. 그걸 느끼고 할머니한테 ‘엉겨 붙어 있구나’ 싶었어요. 제 깊은 곳과 얽혀 있는 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="정서적인 교류가 굉장히 각별했다는 느낌이 들어요. 지금 잠깐 얘기만 나눠 봐도요."
          a="(사전 질문지에 제시된) ‘노스탤지어’ 개념을 보면 현실의 결핍과 불안을 해결하기 위해서 과거의 기억을 선택적으로 불러들인다고 되어 있던데, 제가 그러고 있는 것 같아요. 그러니까 할머니를 제 삶에 이용하는 것 같다는 생각이 들어요. 할머니가 아프다는 사실 자체를. 평소에는 할머니가 아팠는지 어땠는지도 다 까먹고 살지만 그건 제가 살기 위해 그러는 것 같아요. 계속 생각하면 살 수가 없으니까."
          a2=""
          br={true}
        />
        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="할머니가 지금 아프신데도 제가 할머니를 챙겨야 한다는 생각을 한 번도 해 본 적 없는 것 같아요. 그 정도로 할머니는 챙김 받는 걸 싫어하시고 누군가한테 짐이 되는 걸 굉장히 싫어하시죠. 다가가면 앵기고 싶은 사람, 내가 아기가 되는 사람. 그리고 배우는 걸 무척 좋아하시고 글씨도 잘 쓰시고. 저는 할머니만의 정리법, 깔끔함, 집안을 관리하는 방식이 좋았어요. 제 근간을 형성해 준 사람이라 여기고 살고 있죠. 제가 미적인 것에 되게 예민한데 어떤 미적 기준을 할머니로부터 확립한 것 같아요. 요즘 SNS도 많고 잡지도 많고 내로라하는 사람들이 너무 많잖아요. 하지만 결국 제가 돌아가는 곳은 할머니 옷이거나 옛날에 입었던 옷이죠."
          a2=""
          br={false}
        />
        <QandA
          q="할머니께 미적 기준을 물려받았다는 대답은 처음 들어봐요. 특별한 계기가 있었던 걸까요?"
          a="어떤 사건이 있었던 건 아니고 생각해 보면 운인 것 같아요. 부모를 선택하는 게 아닌 것처럼 조부모도 내가 선택하는 게 아니잖아요. 제가 추구하는 가치가 할머니께 있었고 가지고 있는 기질 자체가 할머니와 맞았던 것 같아요. 할머니들이 알록달록한 옷 되게 많이 입으시잖아요. 저희 할머니는 죽어도 안 입으세요. 무조건 무채색으로, 심플한 색. 새로 안 사시고 그냥 기워 입고. 근데 기워 입을 때도 뭔가 당신만의 포인트를 하나씩 넣으셨어요. 옛날에 저랑 제 동생이 할머니집에 낙서를 했을 때는 할머니가 색연필을 가지고 그걸 다시 그림으로 바꾸어 놓기도 하셨죠."
          a2=""
          br={false}
        />
        <QandA
          q="할머니가 정말 감각이 있으셨네요."
          a="그래서 제가 흔들리지 않는 것 같아요. 세상에 예쁜 게 너무 많지만 별로 불안하지 않아요. 뭘 선택해야 할지 고민하다가도 나한테 어울리는 것이 뭔지 어느 순간 깨달은 것 같아요. "
          a2=""
          br={false}
        />
        <QandA
          q="할머니가 낙서로 그리신 그림, 혹시 사진 있으세요? "
          a="핸드폰에 있는데 보내드릴게요."
          a2=""
          br={true}
        />

        <img src="/assets/interview/2-1.svg" alt="낙서" />
        <br />

        <QandA
          q="할머니에 대한 다른 가족들의 생각은 어떤가요? ○○님과 같은가요? "
          a="저에게는 할머니인데 엄마에게는 엄마고 아빠에게는 장모잖아요. 그리고 전 할머니에게 첫 손녀딸이지만 동생은 두 번째 손주라서 각자에게 의미가 많이 다른 것 같아요. 엄마와 얘기하다 보면 ‘할머니가 옛날에 이랬다’면서 속상한 경험을 얘기하기도 하지만 할머니를 정신적 지주라고 생각하더라고요. 제가 모든 걸 알 수 없지만 엄마와 딸의 관계가 다들 그렇듯이 애증이 있죠. (...) 가족으로 만난 이상 한 사람을 오롯이 대하는 게 너무 어렵고 태어날 때부터 가족 내에서 정해진 역할이 있잖아요. 난 할머니고 넌 손녀고. 이게 정해져 있다 보니 한 사람으로 온전히 바라보려 시도 해도 자꾸 놓치게 되는 것 같아요. 그래서 할머니가 아프시고 나서 저희 가족끼리 공통된 생각을 하게 된 것 같아요. (할머니를) 이제 삶이 얼마 남지 않은 어떤 한 여자로 여기고 ‘이 사람도 무서웠겠구나’ 하고 사람 자체를 바라보려 해요."
          a2=""
          br={false}
        />
        <br />

        <SubTitle title="² 기억" />
        <img src="/assets/interview/2-2.svg" alt="가는 길" />
        <br />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="옛날에 비해서 거리가 가까웠다는 걸 매번 느끼는 것 같아요. 그냥 가면 되는데 할머니집 가는 걸 왜 그렇게 어렵게 생각했지 싶어요. 그리고 아파트 단지 안으로 통하는 계단이 있었는데 마트 갔다가 아파트로 돌아오면 이 계단이 엄청 크게 느껴졌거든요. 근데 요새 가면 너무 작아요. 너무 작더라고요. 그때는 계단이 놀이터일 정도로 컸는데 지금은 그냥 너무 작은 계단이더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니가 ○○님을 어떻게 맞아주셨나요?"
          a="할머니가 맛있는 음식으로 절 맞이해 주셨죠. 음식과 할머니집 냄새 나는 잠옷 그리고 따뜻한 장판으로."
          a2=""
          br={false}
        />
        <QandA
          q="그중에서 특별히 좋아하는 음식이 있었나요?"
          a="엄청 많은데 지금 생각나는 건 시금치 무침인데요. 할머니가 마늘을 엄청 넣어서 만든 시금치 무침이 있거든요. 이게 진짜 맛있고요. 어렸을 때 제가 새벽에 배앓이를 하면 할머니가 식소다를 물에 풀어 먹여 주셨나, 그래서 졸린 눈을 비비면서 막 마셨던 기억이 안 잊혀요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올렸을 때 기억나는 공간이 있을까요?"
          a="현관에 들어서면 바로 오른쪽에 방이 있었는데 그 방에 들어가면 할머니가 저 편하게 자라고 문을 닫아주셨어요. 지금은 리모델링을 해서 예전 같은 느낌이 사라지긴 했지만 거기에 있으면 할머니집인데도 되게 불편한 느낌이 들었어요. 그 방에서 이상한 꿈을 꿨던 게 기억이 나요. 눈을 떴는데도 그 꿈에서 헤어나오지 못하고 그랬던 게 왜 기억나는지 모르겠네요."
          a2=""
          br={false}
        />
        <QandA
          q="그 방 풍경이 어땠나요?"
          a="뭐가 없었던 것 같은데. 침대도 아니고 바닥에서 잤거든요. 거의 이불밖에 없었던 것 같아요. 또 기억나는 곳이 있다면 거실에 실크 재질의 하얀 커튼이 있었거든요. 그 커튼 뒤에 숨어서 동생이랑 많이 놀았어요. 거기 숨어 있을 때 너무 편안했어요. 이렇게 가리면 내가 안 보일 것 같은 그런 느낌."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집 내부 인테리어는 어땠나요?"
          a="거실에 있는 등은 일본풍이었어요. 장판은 노란색이 아니고 하얀색이었는데 거기에 제가 볼펜으로 낙서를 열심히 하고 놀았고 할머니가 열심히 물파스로 지우셨죠. 하지만 저한테 한 번도 나무라지 않으셨어요."
          a2=""
          br={true}
        />
        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="할머니집을 떠올리면 생각나는 특정한 물건이 있나요?"
          a="잘 가꿔진 화초들이요. 왜 엄마나 할머니는 그렇게 화초를 죽이지 않고 잘 기르는지. 항상 그렇게 생생하고 흙 위에 나무껍질이나 달걀 껍데기 같은 거 부서져 있고. 그런데 이 화초들이 항상 반짝반짝 빛나지만 나중에 얘네가 죽는다면 할머니가 그걸 돌볼 힘이 없다는 거잖아요. 그게 얼마나 슬플까요. 할머니가 죽으면 이 화초들도 빠르게 빛을 잃겠지 싶어요."
          a2="어느 날 저 혼자 할머니집 거실에서 잤었거든요. 원래 잠을 잘 자는데 그날은 멍하니 앉아서 바깥을 보다가 문득 집이 중요한 게 아니란 걸 깨달았어요. 할머니가 없으면 이 집도 별로 나한테 안 소중한데. 할머니가 있어서 할머니가 오린 종이, 할머니가 깎은 연필, 이런 거 하나하나 다 나한테 너무 소중한 거고 할머니가 없어지면 그런 것도 별로 의미가 없구나. 사람이 없어도 그 물건으로 사람을 계속 기억하게 되는데 그것도 한계가 있구나 싶더라고요."
          br={true}
        />
        <QandA
          q="할머니집을 떠올릴 때 주로 어떤 감정을 느끼나요?"
          a="(할머니집에) 가기 전이랑 가고 나서가 좀 달라요. 가고 나면 내가 원래 이렇게 좋아했던 곳인데 왜 이렇게 가기 망설였지 싶은데 가기 전까지 너무 힘든 것 같아요. 솔직한 말로는 부담도 되고. 근데 가고 나서는 진짜 ‘내가 원래 이런 사람이었지’ 다시 알게 되고 즐거워요. 요즘은 할머니가 나이도 있으시니까 혹시 그사이에 뭔가 변화가 있을 것 같아서 두려운 마음도 있어요. 그리고 가족들과 함께 있으면 모두가 할머니와의 관계가 각자 다르잖아요. 그래서 제가 이 사이에서 어떤 스탠스를 취해야 되는지 어려울 때가 있어요. 저는 모든 사람의 입장이 이해돼서 슬프더라고요. 그래서 차라리 할머니랑 둘이 있는 게 편한 것 같아요. 그냥 친구처럼 노래도 부르고 누워서 노닥거리고 드라마 보면서 욕하고."
          a2=""
          br={true}
        />
        <img src="/assets/interview/2-3.svg" alt="풀밭" />
        <br />
        <QandA
          q="할머니집과 관련된 가장 강렬한 기억이 있나요?"
          a="동생이랑 빨가벗고 막 돌아다니면서 놀면 할머니가 쫓아다니면서 한입씩 뭐를 먹여요. 귀여우니까 아이고 잘했다 이러면서. 할머니만의 표현으로 “신통방통 꼬부랑통이네~” 하셨던 기억이 떠오르네요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 생각하면 그리워지는 냄새나 소리가 있을까요?"
          a="냄새가 있는 것 같아요. 제가 들고 다니는 손수건인데 할머니가 줬어요. 돌아다니다 보면 할머니의 냄새가 사라지는데 빨면 또다시 나는 느낌? 맡으면서 약간 안정을 취해요. 할머니집 냄새라서 할머니 옷에도 다 배어있는 특유의 살냄새가 나요."
          a2=""
          br={false}
        />
        <QandA
          q="언제부터 들고 다녔어요?"
          a="항상 들고 다닌 지는 2년 정도밖에 안 됐는데 그전에는 ‘손수건을 왜 들고 다니지’라고 생각했어요. 근데 들고 다니니까 쓸 일이 꽤 많더라고요. (...) 사실 이건 새 것이라서 (할머니 냄새가) 그렇게 많이 나지는 않아요. 근데 제가 계속 세뇌를 하나 봐요. 내가 편안해지려고. 내가 맡아야 하니까."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올리게 하는 물건이 또 있나요?"
          a="물건이라고 하기엔 좀 그렇지만 할머니 글씨체요. 뭘 계속 쓰세요. TV 보면서도 쓰시고 노래 가사도 쓰시고. 그리고 할머니가 제가 어렸을 때부터 매년 제 생일 때마다 10만원 씩 모아 놓았다가 제가 20살이 될 때 손편지랑 같이 주셨거든요. 그 뭉치가 가장 할머니를 떠올리게 하는 것 같아요. 평생 그 마음을 모를 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="그 순간으로 돌아가고 싶은 할머니집의 기억이 있나요?"
          a="보고 싶은 모습은 있어요. 원래 할머니가 얼굴이 되게 반질반질했거든요. 그 반질반질한 이마가 보고 싶어요. 하지만 슬프게도 안 되는 건 안 되는 것 같아요. 이미 어떻게 돌이킬 수 없는 건 어떻게 할 수가 없잖아요. 이제 영원히 못 하는 게 생겼구나. 반질한 이마가 그립네요."
          a2=""
          br={false}
        />
        <QandA
          q="반질반질. 아까 화초와도 비슷한 느낌이에요. 빛나던 것들이 이제 빛이 닳아가는 걸 느끼고 계신 것 같아요."
          a=""
          a2=""
          br={false}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니에 대한 기억이 가족들이 기억하는 것과 달랐던 적이 있나요?"
          a="제가 아는 할머니는 되게 침착하고 차근차근한 사람이었는데 얼마 전에 할머니께 물어보니 할머니는 음식을 꼭꼭 씹어서 먹지 않는대요. 틀림없이 꼭꼭 씹어서 먹는 줄 알았거든요. 그런데 항상 꿀떡꿀떡 삼켰다고 하시더라고요. 아마도 예전부터 시간이 없어서 그러셨겠죠. 저는 또 할머니가 어떤 식으로 지내시는지 까지는 살피지 못했던 거죠."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="저는 치매가 생각나는데요. 노인이 돼서 제정신으로 죽을 수 있으면 그건 꽤 괜찮은 삶이라는 생각이 들더라고요. 저희 할머니는 기억력이 엄청 좋으셨는데 어느 날 갑자기 이런 말을 하시더라고요. “정신이 흐려지면서 사실관계가 맞춰지지 않으면 사람이 죽는 거구나” (...) 옛날에 제가 재수할 적에 근처에 있던 요양병원이 있었는데 그걸 보면 앞에서 항상 멍한 표정으로 담배만 피던 할머니 할아버지들이 계셨거든요. 그런 이미지들이 떠올라요. 기억이 사라지면서 생을 마무리하는."
          a2=""
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화에 대한 소감이 있다면."
          a="슬픔에 특수성도 있고 보편성도 있다고 생각해요. 사실 사랑하는 사람을 잃는 건 사람이 살아가면서 겪는 굉장히 보편적인 경험이잖아요. 그럼에도 나한테 내 경험은 특별하니까. 이 가운데서 중심을 잡는 게 좀 힘들었던 것 같아요. 지금도 물론 힘들고요. 원래는 저랑은 관련이 없는 일이라고 생각했었는데 나한테만 안 일어나는 나쁜 일, 나만 비껴가는 불행 같은 건 없더라고요. 우리 할머니가 그렇게 큰 병에 걸릴 거라고 생각치도 못했고. 근데 시간이 지나면 받아들여야 하는 것 같아요."
          a2=""
          br={false}
        />
      </div>
    </div>
  )
}

export function Subee() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="3" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="오늘 제가 할 얘기는 거의 친할머니에 대한 것이지만 이 질문의 대답은 외할머니, 친할머니 모두 ‘음식’이네요. 전공을 옮기기 전 영화과를 다니면서 찍었던 단편 영화의 인트로 부분에도 외할머니 하면 떠오르는 어떤 음식에 대한 에피소드를 삽입했었어요. 그리고 현재는 친할머니와 함께 레시피북을 만드는 작업을 하고 있기 때문에 ‘음식’이라고 말할 수 있을 것 같습니다."
          a2=""
          br={false}
        />
        <QandA
          q="레시피북이요? 초반부터 굉장히 흥미로운 정보인데요. 이후 질문에서 자세히 여쭤볼게요."
          a=""
          a2=""
          br={false}
        />
        <QandA
          q="할머니와 작업까지 함께하실 정도면 평소 교류가 잦으실 듯한데, 할머니와 정서적으로 친밀하신가요?"
          a="어렸을 때보다 나이가 들수록 더 가깝게 느껴져요. 대부분 반대인 경우가 더 많잖아요. 어렸을 때 할머니와 가깝게 지내다가 소위 말해 머리가 크고 데면해지는데 저는 완전 반대예요. 나이가 들고 할머니가 저를 특별히 애정하셨다는 걸 알고 난 후에 애틋한 감정이 커졌달까요."
          a2=""
          br={true}
        />
        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="확실하게 제 인생에서 가장 드센 분이라고 생각해요. 그러니까 정말 ‘나보다 더 센 사람’. 제가 어렸을 때 모종의 이유로 고모를 굉장히 무서워했어요. 고모가 저를 아끼셨던 것과 별개로 제가 처음으로 ‘나도 기가 죽을 수 있구나’ 느낀 드세고 강한 분이셨는데, 할머니는 그런 고모를 직접 키워낸 최고로 센 사람인 셈이었죠."
          a2=""
          br={false}
        />
        <QandA
          q="그렇게 느끼게 된 계기가 있었나요?"
          a="할머니가 시골에서 꽤 오랜 시간 동안 부녀회장으로 활동하시면서 그 일대를 호령하시는 모습들을 수없이 봐왔죠. 일일이 설명하기 힘든 크고 작은 에피소드가 많아요. 저는 여장부라는 단어를 사람으로 만든다면 우리 할머니가 될 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="다른 가족들이 생각하는 할머니는 어떤 분인가요? ‘여장부’라는 평가에 다들 공감하시는지."
          a="저는 할머니의 1대 자녀가 아니니까 애틋하다는 감정의 농도가 다른 것 같아요. 전 할머니가 여전히 강한 분이라고 생각하거든요. 나이가 드신 후 쇠약해지신 것과 별개로 여전히 불호령 하는 마인드나 꿋꿋한 심지는 건재하다고 여기는데, 아빠나 큰아빠들 그리고 고모는 이제 할머니를 전보다 애틋하게 생각하시는 것 같아요. 그럴 때 내가 생각하는 할머니라는 사람의 크기가 아빠가 생각하는 것과는 조금 다르다는 걸 느끼죠."
          a2=""
          br={false}
        />
        <SubTitle title="² 기억" />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="제가 기억하는 할머니집과 지금 할머니집의 모습이 같아요. 자식들이 모두 결혼한 후 산속에 전원주택을 지어서 사셨는데 저희는 명절이나 어떤 이유로 (할머니집에) 내려갈 때마다 항상 차를 타고 갔어요. 그 장면이 매우 선명하게 기억나요. 길이 꼬불꼬불해지는 길목에서 차 창문을 내리면 가로등 불빛보다 달빛이 더 환하고 “완전 시골이네” 싶은 순간, 그때부터 40분을 더 가면 도착하는 거죠."
          a2=""
          br={false}
        />
        <QandA
          q="아무도 모르는 본인만의 표지판이 있었던 거군요."
          a="맞아요. 도로명 주소가 있지만 그냥 어떤 길에서 딱 꺾어 들어가면 할머니집이 나온다는 감이 오죠. 할머니집 직전에 흰색 펜션이 있어요. 그 펜션이 보인다? 다 왔다는 신호죠."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에 도착했을 때 가장 먼저 보게 되는 건 어떤 장면인가요?"
          a="할머니가 15년 전부터 진돗개를 키우셨어요. 이름이 순덕이인데 들어가자마자 무자비하게 짖어요. 낮이건 밤이건 24시간 언제든 차가 오면 짖어요. 몇 년이 지나도 우리 가족과 차를 절대 기억 해주지 않고 집을 지키는 본인의 경호 행위에만 최선을 다하는 순덕이, 그리고 우리가 온다고 하면 잠에 들지 못하시고 미닫이 창문 앞에 우뚝 서서 (차가) 들어오는 걸 지켜보는 할머니가 보이죠."
          a2=""
          br={true}
        />
        <img src="/assets/interview/3-1.svg" alt="" />
        <br />
        <QandA
          q="할머니집에서 가장 기억에 남는 방이나 공간은 어디였나요?"
          a="완전 부엌이요."
          a2=""
          br={false}
        />
        <QandA
          q="레시피북이 시작된 그 부엌인가요?"
          a="맞아요. 큰 부엌에는 싱크대와 가스레인지 같은 집기들이 있고 그 뒤로 뒷베란다가 있어요. 여기가 할머니집만의 팬트리인데, 온갖 기름 많이 튀는 일들은 다 여기서 벌어지죠. 그 바로 앞에도 보조 주방이 있어서 전 부칠 때는 여기에 깔아두고 해요. 떠오르는 풍경은 여기서 분주히 움직이는 할머니와 할머니의 지령에 맞춰 공장처럼 일사불란하게 움직이던 가족들의 모습이네요."
          a2=""
          br={false}
        />
        <QandA
          q="다 같이 일하면 정말 복작복작하겠는데요."
          a="저희 할머니는 남자는 쉬고 여자만 일하는 걸 절대 허락하지 않는 분이기 때문에 여자들이 부엌일을 할 동안 남자들도 일을 해요. 주택에 살기 때문에 힘을 써야 하는 일이 너무너무 많거든요. 남자들은 장작을 패거나 벌초를 합니다. 이제 좀 쉬는 것 같다? 바로 끄집어내 새로운 일거리를 주시죠. 할 일이 너무 많아요."
          a2=""
          br={true}
        />
        <QandA
          q="어릴 적 갔던 할머니집에 대한 기억이 지금 방문했을 때와 차이가 있나요?"
          a="위치와 외양은 같지만 제가 느끼는 바가 많이 달라요. 할머니집이 뒤에는 산이 있고 앞에는 물이 흐르는 전형적인 전원주택이라 어렸을 때는 솔직히 싫어했어요. 아파트처럼 견고하지 않고 도시에서 쉽게 볼 수 없는 수많은 벌레들이 있죠. 특히 화장실은 구석에 거미줄이 있어서 지금도 잔뜩 경계하며 샤워해야 해요. 언제 어디서 뭐가 튀어나올지 모르니까. 따뜻한 물을 쓰려면 15분 전에 미리 온수 버튼을 눌러야 하고 난방도 가마솥에 불을 때야 해서 도심에서 느낄 수 없는 사사로운 불편함이 많죠. 어린 마음에는 짜증이 났었어요. 그런데 크고 나니까 다르게 느껴지는 거예요. 일단 이런 생활을 혼자 하시는 할머니가 대단하고 마냥 짜증 났던 것들이 좀 새로웠다고 해야 하나. 여전히 짜증은 나요. 그렇지만 익숙해지면서 내성이 생겼죠."
          a2=""
          br={false}
        />
        <QandA
          q="할머니집 자체의 변화보단 그걸 받아들이는 본인의 변화가 크네요."
          a="맞아요. 그리고 약간 즐길 줄 알게 됐고요. 이렇게 살아야 하는 기간이 10일이 채 안 된다는 걸 제가 아는 거죠. 지금 짜증이 나도 어차피 다시 도시로 간다는 걸 알고 있으니까."
          a2=""
          br={false}
        />
        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="일상 속에서 마주칠 때마다 할머니집을 떠올리게 하는 물건이나 장소가 있나요?"
          a="무조건 두부예요. 두부를 볼 때마다 할머니 생각이 나요. 부엌에 있는 가마솥에서 할머니가 가장 크게 만드시는 음식이 두부거든요. 그리고 제가 어렸을 때부터 그 작업을 많이 도왔어요. 두부 만들 때 나는 냄새와 몽글몽글 맺히기 시작할 때 할머니가 손으로 떠서 입에 막 넣어주셨던 기억들이 많아서 두부만 보면 할머니집 생각이 나죠. 밖에서 사 먹을 때마다 돈이 정말 아깝고 가끔 진짜 맛있는 두부 요리를 먹으면 그렇게 생각나요."
          a2=""
          br={true}
        />
        <QandA
          q="이 타이밍에 아까 말씀하신 레시피북에 대해 여쭤보고 싶은데요. 먼저 책에 대해 간단히 설명해 주실 수 있나요?"
          a="말 그대로 할머니의 삶이 담긴 메뉴들의 레시피가 담겨 있는 책입니다. 기획, 제작, 편집은 손녀인 제가, 저자는 할머니께서 맡으신 2인 독립 서적입니다."
          a2=""
          br={true}
        />
        <QandA
          q="레시피북을 만들게 된 계기가 있나요?"
          a="할머니가 틈날 때마다 무언가 적어 두신 까만색 양장 노트가 있어요. 레시피라고 하기도 어렵고 그냥 할머니만 알아볼 수 있는, 예를 들어 김치 레시피가 4줄로 끝나는 그런 노트인데 제가 우연히 그 존재를 알게 됐어요. 노트를 재미 삼아 명절에 받아 왔는데 거기 안에 담겨 있던 레시피와 덧붙인 말들이 무척 유의미하다고 느꼈죠. 만약 할머니가 자서전을 낸다면 그건 레시피북일 거라고 생각할 정도로 할머니와 음식이 관련 깊기 때문에, 언젠가 이 노트로 뭔가 하면 좋겠다 싶었어요. 우리 모두가 영원을 사는 건 아니니까 (할머니가 돌아가신 후에) 당신의 부재를 어떻게 메울 수 있을지 진지하게 고민해 본 적도 있고… 또 그 누구도 할머니의 김치를 다시 구현해 내지 못한다면 우리 가족은 김치를 먹지 못하고 죽는 것인가… 진심으로 걱정이 되어 (레시피북 제작을) 시작하게 되었습니다."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 함께 만드는 책과 그 과정이 ○○님께는 어떤 의미인가요?"
          a="사실 저도 (이 프로젝트가) 안 될 거라고 은연중에 생각했어요. 할머니가 열정적인 자세로 임해주셔야 저도 할 수 있는데 처음에는 겁먹고 두려워하셨거든요. 막 사용하시라고 연습장을 드렸는데 거의 1년 4개월 뒤에야 돌려받았어요. 중간중간 전화 드릴 때마다 진도가 더뎌서 솔직히 불가능하다고 생각했었는데, 나중에 꾹꾹 담아 적어서 주신 걸 보고 그때 무조건 해야겠다 결심했고 엄청 울컥했어요. 할머니가 이 연습장을 채우기 위해 다른 종이에 연습하신 것도 발견했는데 엄청 쌓여 있는 종이 뭉치가 정말 마음 찡하더라고요. 사실 저는 완성된 음식을 받아먹기만 한 입장이었으니 이렇게나 많은 과정을 거치는지도 몰랐어요. 수많은 노고가 지금까지의 밥상을 채워 왔다는 걸 생각하면 이보다 더 큰 노동과 헌신이 있을까 싶어요. 그리고 할머니를 기억할 수 있도록 의미와 물성이 있는 것이 남았으면 좋겠다고 생각해 왔는데 이 레시피북이 그런 결과물이 될 것 같아요. 할머니를 음식으로써 가장 많이 그리워할 것 같다는 점에서 좀 더 유의미한 기록물이 될 거라고 생각합니다."
          a2=""
          br={true}
        />
        <img src="/assets/interview/3-2.svg" alt="" />
        <img src="/assets/interview/3-3.svg" alt="" />
        <br />
        <QandA
          q="다시 기억에 대한 질문으로 돌아가 볼게요. 할머니집을 떠올렸을 때 그리운 기억이 있나요?"
          a="아직도 기억나는 게 고등학생 때 할머니집에 친구들을 데리고 갔었어요. 할머니가 마당에서 직접 만든 떡갈비를 석쇠에 구워 주셨거든요. 보통 “상추 가져와라”는 말은 냉장고를 열어서 상추를 가져오라는 건데, 할머니집은 주변이 다 밭이라서 저희는 밥 먹다가 별안간 상추를 따러 가야 했죠. 그렇게 다 같이 떡갈비 먹었던 게 기억에 남아요. 벌레 우는 소리와 살짝 진 노을에 선선한 여름밤. 고1 때쯤 여름방학이었는데 저는 그날이 저의 10대를 대표하는 느낌이에요."
          a2=""
          br={true}
        />
        <QandA
          q="그 기억이 현재의 ○○님에게도 영향을 끼치나요?"
          a="영향이라기보다는 가장 순수한 기억인 것 같아요. 아무런 불평불만이 없는 상태는 그 시기에만 누릴 수 있는 특별한 기분인 것 같거든요. 그리고 친구들 없이 나만 있었으면 그렇게 찬란한 기억으로 남지 않았을 것 같은데, 우정과 사랑과 가족애가 결합한 감정인 거죠. 제 삶에서 가장 영화 같은 순간이라고 생각해요."
          a2=""
          br={false}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집에서 왠지 모르게 꺼려졌던 공간이나 물건이 있었나요?"
          a="큰아빠들과 할머니, 할아버지가 직접 황토를 구해서 쌓고 다듬고 하며 거의 4년 정도 걸려 완성한 황토방이 있어요. 막 생겼을 때부터 그냥 들어가고 싶지 않았는데 할아버지가 거기서 돌아가셨어요. 할머니가 여행을 가신 동안 혼자 계시다가 그 방에서 돌아가셨다고 전해 들었어요. 그 이후로 절대 쳐다볼 수 없더라고요. 황토 냄새 날 것 같고 좁고 후덥지근한 공간에 굳이 왜 들어가야 하는지, 할머니는 저걸 왜 원하셨는지 의문이었거든요. 솔직히 말하면 그 방이 집의 미관을 정말 망친다고 생각했었는데 그 사건 이후로는 아예 발도 들이지 않았어요. 이제는 무서운 감정이 큰 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에 대한 본인의 기억과 가족들의 기억이 달랐던 적이 있나요?"
          a="저는 할머니집이 굉장히 크다고 기억하거든요. 아빠는 그렇게 느끼지 않는 것 같아요. 예를 들어서 할머니가 새로운 김치냉장고를 들이고 싶다 하시면 아빠는 그 작은 집에 뭘? 하시지만 저는 그런 것쯤 100개도 들어갈 수 있을 것 같은 느낌인 거죠. 지금은 저도 몸이 컸으니까 예전만큼은 아니지만 어렸을 때는 할머니집이 너무너무 광활하다고 생각했던 것 같아요. 그 안에서 수많은 일들이 벌어지기도 했고요. 우리 집에서는 온 가족이 다 모여 명절 음식을 하거나 사촌들과 레슬링이나 숨바꼭질을 하는 일이 없잖아요. 근데 할머니집에서는 그런 일들이 벌어지니까 어떨 때는 운동장 같고 어떨 때는 놀이터 같고 제가 그 장소를 정의하는 개념이 매번 바뀌었는데 아빠한테는 계속 부모님 집이기만 했던 거죠."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="저는 가짜 아날로그와 진짜 아날로그가 있다고 생각해요. 미디어에서 보여주는 전형적인 할머니집의 모습과 할머니의 삶은 ‘가짜’인 경우가 많죠. 저는 마인드가 아날로그인 게 중요하다고 생각해요. 과정의 복잡함이나 번거로움을 떠나서요. 할머니집이라는 공간을 향유하는 할머니가 삶을 대하는 태도, 그게 진짜 아날로그가 아닐까요. 미디어에서는 할머니집, 할머니 물건, 할머니라는 대상 자체를 오래된 것으로 표현하는 데 주목하잖아요. 저는 그것보다 불편함을 감내하고 계속 유지해 나가려는 우리 할머니의 태도가 곧 아날로그, 그러니까 할머니와 할머니집의 본질이라고 생각해요. "
          a2=""
          br={true}
        />
        <QandA
          q="말씀하신 ‘아날로그’한 삶의 태도, 혹은 할머니의 방식이 ○○님께도 영향을 미쳤나요?"
          a="예전에 영화과를 다닐 때 전공 수업에서 그런 삶에 대한 다큐멘터리를 기획했었어요. 그때 저는 할머니 할아버지의 끼니에 집중하는 삶을 조명하고 싶었거든요. 삼시 세끼에 집중하는 삶, 이보다 치열할 수 있나. 이게 너무 멋있고 이보다 치열하게 사는 인간이 있을까 싶었어요. 레시피북도 이런 태도를 향한 존경심으로부터 시작했던 거고요."
          a2="(...) 제 자립심은 아빠로부터, 당당함은 할머니로부터 왔어요. 드센 여성으로 살아도 전혀 문제가 없다는 걸 할머니께서 몸소 보여주셨죠. 여장부로 태어나 목소리를 내고 일대를 호령하는 법도요. 저는 삶에 최선을 다한 사람만이 가질 수 있는 당당함을 할머니로부터 배웠어요. 할머니가 일평생 무척 열심히 사셨기 때문에 모두가 할머니 얘기에 고개를 숙이고 따를 수 있었다고 생각하거든요. 그런 부분을 많이 배웠죠."
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화를 마친 소감이 있다면."
          a="저는 집이 대단한 이유는 한 사람의 삶을 견뎌내기 때문이라고 생각해요. 삶을 완전하게 담아내는 곳이기에 위대하고 양면성이 있는 것 같아요. 그래서 무서울 수도 있고 포근할 수도 있고 안전할 수도 있는, ‘취향’이 없기에 재밌는 공간이라고 생각해요. 단편적인 시각일 수 있지만 할머니집을 어떤 인테리어라고 정의하기 어렵잖아요. 한 단어로 말하기 애매하고 지난 삶의 모든 것이 함축된 곳이죠. 예를 들어 요강이 있지만 한쪽에는 자식들이 선물로 준 비스포크 냉장고가 있는 그런 곳. 그렇기 때문에 재미있고 이야깃거리가 많을 수밖에 없는 것 같아요."
          a2=""
          br={false}
        />
      </div>
    </div>
  )
}

export function Minsu() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="4" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="할머니 생각하면 제일 많이 떠오르는 건 곱슬곱슬한 머리, 할머니 파마. 늘 항상 지금까지도 하고 계시니까요. 그리고 왠지 모르겠는데 할머니집에 있었던 숟가락과 젓가락 생각도 나고요. 집 근처에 있던 천과 산의 장면이나 할머니가 그 집에서 구멍가게도 같이 하셨어서 손님들에게 과자를 줬던 모습들도 생각이 납니다."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 정서적으로 친밀한 편이신가요?"
          a="제가 초등학교 1학년 때부터 4학년 때까지 할머니와 같이 살았어요. 부모님이 회사에 가시니까 봐줄 사람이 없어서 4년 정도 같이 살았는데 그래서 그런지 심리적으로 가까운 일들이 많았고요. 사실 할머니와 싸우기도 많이 싸웠어요. 단순히 그리운 할머니라기보다는 저를 키워줬던 할머니라서 약간 엄마처럼 싸웠죠. 그런 것들이 많아서 정서적으로 되게 가깝다는 생각이 들죠."
          a2=""
          br={true}
        />
        <QandA
          q="같이 살던 시기를 제외하고도 할머니를 자주 찾아뵙는 편이셨나요?"
          a="명절 때는 늘 찾아뵙는 경우가 많았고 어렸을 때부터 부모님이 계속 일을 하시다 보니까 방학 때 할머니네 한 달 정도 맡겨지기도 하고, 할머니집에 좀 오래 있었어요. 오랜만에 생각해 보니까 그 집에 있었던 시간이 꽤 길더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="할머니는 되게 많이 희생적인 사람, 그러니까 남뿐만이 아니고 자식들한테도 피해 끼치기 싫어하는 사람이었어요. 그래서 자기가 원하는 바를 절대 얘기 잘 안 하시고 다 괜찮다고 하는 그런 스타일이셨던 것 같아요."
          a2=""
          br={false}
        />
        <QandA
          q="그런 성격이란 걸 언제 아셨어요?"
          a="거의 커서 알았죠. 어릴 때는 몰랐다가 커서 보니까 그렇더라고요. 저희 부모님도 할머니 의중 파악하기를 항상 제일 힘들어하시고 필요한 게 분명히 있는데 얘기를 절대 안 하셨어요. 같이 살지 않으니까 전화만으로는 의중을 파악하기가 힘들죠. 제일 어려운 점은 아픈데도 얘기 안 하는 거. 사실 전화로 얘기하면 안 아프다고 하시는데 가서 보면 분명히 아픈 게 보이거든요. 피해 주는 걸 너무 싫어하셔서 자식들한테 좀 자주 오라는 얘기도 안 하세요. 누가 봐도 자주 왔으면 하시는 게 보이는데."
          a2=""
          br={true}
        />
        <QandA
          q="성인이 되고 나서도 할머니를 뵈러 가시나요?"
          a="네 종종 가요. 가서 노인정에 라면 한 박스 사다 드리고. 소주도 한 박스 사 들고 가고. 가끔 아주 가끔."
          a2=""
          br={true}
        />
        <img src="/assets/interview/4-1.svg" alt="" />
        <SubTitle title="² 기억" />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="저는 약간 그런 게 있어요. 추억이 있는 장소는 가끔 가 보거든요. 예전에 갔던 군부대 근처나 한 번씩 가 보고 그러거든요. 그러다 보니 예전 할머니집도 몇 번 가 봤죠."
          a2=""
          br={true}
        />
        <QandA
          q="그러면 비교적 최근에도 할머니집을 방문해 보신 거네요. 할머니집으로 가는 길의 풍경은 어떤가요?"
          a="지금은 길이 많이 깨끗해졌는데 원래 한 쪽에 천이 흐르고 반대편에 집들이 모여 있고 그 옆에 기찻길, 뒤에 산이 있는 마을이었어요. 거의 천 모양대로 길을 내서 길이 엄청나게 구불거렸죠. 도로포장도 옛날식 시멘트 길이었어요. 동네 자체도 옛날 탄광촌 같은 느낌이었던 걸로 기억해요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니가 ○○님을 어떻게 맞아주셨나요?"
          a="할머니가 표현이 살가운 분은 아니었어요. 지금도 마찬가지인 것 같은데 그냥 웃어주셨어요. “왔니” 하고 웃어주는 거 외에 특별한 건 없어요. 생각해 보니 옛날부터 지금까지 쭉 그랬던 것 같아요. 항상 가서 “할머니 나 왔어” 그러면 “왔니” 웃어주고 그다음에 각자 자기 방으로 들어가고. 무뚝뚝한 사람은 아닌데 얘기하면 불편해할까봐 표현을 잘 안 하셨던 것 같아요."
          a2=""
          br={false}
        />
        <QandA
          q="할머니집 인테리어는 어땠나요?"
          a="구멍가게는 바닥이 거의 시멘트라 되게 차가웠던 기억이 나고요. 그 옆에 있는 방들은 약간 단이 높았어요. 아래가 시멘트 바닥인 건물이라 그런지 자는 공간은 좀 높았던 기억이 나요. 정말 낡은 건물이라 지붕도 양철판이었고 문도 외풍이 많이 들 것 같은 느낌이었어요. 지금은 안 그렇지만 그때 그 동네엔 다 그런 집들밖에 없었죠."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올릴 때 기억에 남는 공간이 있을까요?"
          a="할머니집이 구멍가게를 겸해서 들어가자마자 가게가 먼저 보였어요. 몇 개 없었지만 조그만 공간에 과자 같은 것들이 쌓인 가판대가 있었죠. 거길 지나서 안쪽으로 더 들어가야 잘 수 있는 공간이 있고요. 그리고 오른쪽에 기찻길 보이는 방이 하나 있는데 거기가 제일 기억에 남아요. 할머니가 “밥 잘 먹어야 기차 간다” 하면서 저에게 밥을 먹였던 기억이 나거든요. 근처에 시멘트 공장이 있어서 한 2~30대쯤 되는 기차가 지나가는데 그걸 하염없이 봤던 기억이 나요."
          a2=""
          br={false}
        />
        <QandA
          q="기억에 남았던 이유가 있나요?"
          a="기차가 보이는 공간을 제가 좋아하고 즐거워했던 것 같아요. 놀 게 없으니까 앞에 있는 천에서 놀거나 기차를 보거나 했던 게 대부분이라. 많은 시간을 보낸 일이다 보니 제일 기억에 남은 것 같아요. 그리고 구멍가게에는 좋아하는 과자가 많이 쌓여 있어서 먹고 싶으면 먹을 수 있었어요. 할머니가 안 준 적이 없었거든요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올리면 생각나는 특정한 물건이 있나요?"
          a="숟가락이 제일 많이 기억나요. 할아버지가 군대에서 썼던 숟가락인데 그게 다른 것들보다 유독 입에 넣는 부분이 엄청 커서 제가 어렸을 때 그 숟가락으로만 밥을 먹었어요. 제가 그걸 좋아하니까 할머니가 밥을 줄 때 항상 같이 주셨죠. 다른 숟가락이랑 다르게 생겨서 좋아했는데 제가 중학교 가기 전까지 계속 썼던 걸로 기억해요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/4-2.svg" alt="" />
        <br />
        <QandA
          q="일상에서 문득 떠오르는 할머니와의 추억이 있나요?"
          a="어릴 때 무척 오랫동안 할머니와 함께 걸었던 기억이 있어요. 시골은 버스 배차 시간이 기니까 할머니가 그냥 걸어가자고 해서. 되게 오래 걸었거든요. 어린애가 그렇게 잘 걷더라고 할머니가 말씀하시던 기억이 그 이후로도 제일 많이 나는 것 같아요."
          a2=""
          br={false}
        />
        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="할머니집 하면 기억나는 냄새나 소리가 있을까요?"
          a="할머니가 먹고사는 게 너무 바쁘고 힘드셨기 때문에 요리를 못 하는 편이세요. 할머니가 제일 잘하는 요리가 계란 후라이일 정도로 요리를 못하셨는데, 그래도 할머니집에 가면 밥을 해 주시잖아요. 근데 그 냄새가 싫지 않았던 것 같아요. 된장찌개 끓여주시고 계란 후라이 해주시고 그러셨는데 되게 맛있지는 않았지만 그래도 먹을 만한 냄새가 났어요. 입이 예민한 편이 아니어서 그냥 주는 대로 잘 먹었던 것 같아요. 그리고 할머니가 음식에 관심이 없으니까 음식을 여러 가지 차리지 않고 한 그릇에다 밥이랑 두부랑 다 넣어 주셨거든요. 스테인리스 그릇 하나에 계란 넣고 밥에 간장에 다 넣고 비벼 먹는, 할머니가 해 주시는 음식이 맨날 그런 식이었거든요."
          a2="그리고 쥐 소리. 처음에는 그게 뭔 소리인지 몰랐었는데 부모님이 쥐가 왔다 갔다 하는 소리라고 알려주셨어요. 또 기차 소리도 들렸어요. 기찻길이 멀지 않아서 바퀴 굴러가는 소리 듣고. 가마솥에서 불장난할 때 나는 타닥거리는 소리도 좋아했던 것 같고. 새 소리도 많이 났었죠."
          br={true}
        />
        <QandA
          q="그 순간으로 돌아가고 싶은 할머니집에서의 기억이 있나요?"
          a="기차를 보면 항상 할머니집이 생각이 많이 나요. 기차를 보면 자연스럽게 떠오르는 곳이라서. 되게 어린 시절이었는데도 기차 지나다니던 모습이 생생하게 기억나요. 기차가 보이던 곳에서 항상 밥을 먹었기 때문인 것 같아요. 제가 거기서만 밥을 잘 먹어서 항상 그 앞에서 밥을 먹여 주셨거든요. 그래서 기차를 보면 자연스럽게 할머니 생각이 나고, 이제 한번 (할머니집에) 가야 하는데 싶다가도 또 그냥 지나가고."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에서의 경험들이 본인에게 어떤 영향을 끼친 것 같나요?"
          a="할머니 덕분에 아무거나 잘 먹게 되었죠. 어렸을 때부터 주는 대로 먹고 할머니와 함께 살 동안은 계속 그렇게 먹었으니까. 그거 제일 많이 먹었어요. 3분 카레나 3분 짜장."
          a2=""
          br={false}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집에서 왠지 꺼려졌던 공간이나 물건이 있었나요?"
          a="제가 갈 일이 없었던 방이 하나 있긴 한데요. 구멍가게에서 더 안쪽으로 들어가면 방이 하나 있고 오른쪽에 기찻길 보이는 방이 있고 왼쪽에 이 방이 있었는데 기억이 전혀 안 나요. 위험해서 못 가게 했다든지 뭐가 아무튼 있었겠죠."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에 대한 본인과 다른 가족들의 기억이 다른 경우가 있었나요?"
          a="안 그래도 궁금해서 물어봤는데 없더라고요. 제 기억이 거의 그대로 맞아요. 할머니가 이사를 많이 다니셔서 오히려 가족들보다 제가 할머니집에 더 자주 있었어요. 부모님이 성인이 돼서 독립한 이후에는 집이 옮겨지는 바람에 그 집에 올 일이 별로 없으셨고 저만 몇 달씩 맡겨졌으니까 제 기억이 오히려 정확하더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="할머니들이 음식을 되게 푸짐하게 차려주잖아요. 저희 할머니도 음식은 맛이 없지만 굉장히 푸짐하게 차려주셨어요. 저희 할머니가 배추전은 되게 잘 만드셨는데 예전에 맛있다고 하니까 엄청 많이 해주셨어요. 이젠 그렇게 좋아하지 않는데도 갈 때마다 배추전을 그렇게 많이 해주세요. 다른 할머니들이 음식 푸짐하게 주시는 걸 보면 할머니들은 다 그런 것 같습니다."
          a2=""
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화에 대한 소감이 있다면."
          a="할머니에 대해 깊게 생각해본 적이 없었는데 이번 기회를 통해 한 번쯤 생각을 해본 것 같아요. 그 집도 한 번 더 생각하게 되고 한 번 가야 하는데 싶네요. 얼마나 더 사신다고 정말."
          a2=""
          br={false}
        />
      </div>
    </div>
  )
}

export function Seungchan() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="5" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="할머니를 생각했을 때 따뜻함이라는 키워드가 가장 먼저 떠올라요. 가족끼리 친하게 지내서 유대감이 깊기 때문에 긍정적인 감정들, 따뜻하고 포근하면서 언제든 반겨주는, 기쁜 감정들이 먼저 생각납니다."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 정서적으로 친밀한 편이신가요?"
          a="할머니가 바로 옆 동네에 사셨어요. 지금 할머니는 돌아가셨지만 할아버지가 아직 거기 살고 계시고 걸어가면 한 30분, 차 타면 10분 정도 걸리는 거리라 어릴 때는 엄마 퇴근하시고 저녁에 같이 갔죠. 초등학생 때는 일주일에 한 번, 적어도 한 달에 한 번은 갔던 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 어떻게 시간을 함께 보내셨나요?"
          a="어디 놀러 간 기억은 사실 잘 없는 것 같고요. 집이 가깝다 보니 어릴 때는 부모님이 할머니께 저희 형제를 자주 맡기셔서 할머니 댁에서 잘 때도 꽤 있었어요. 그래서 함께 보낸 시간은 보통 할머니 댁에서였죠. 집 앞에 있는 상가를 한 바퀴 돌면서 간식을 사 주셨던 기억이 있어요. 그리고 집에서는 9시에 자야 했거든요. 할머니 댁에는 부모님도 없고 할머니도 늦게 주무시니까 12시까지 자유롭게 TV를 볼 수 있었죠. 투니버스 채널은 자정이 넘어야 재밌는 걸 방영하는데 그때쯤 심슨 같은 프로그램을 봤어요. 그리고 어렸을 때는 성당에 다녔고 그때 제가 ‘복사’를 했어요. 복사는 교회에서 예배를 하듯 성당에서 미사할 때 신부님을 도와드리는 역할인데 아무나 시켜 주지 않거든요. 할머니가 제가 복사하는 걸 보시면서 좋아하셨고 성당도 몇 번 같이 갔던 게 기억나요."
          a2=""
          br={true}
        />
        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="할머니의 가족 관계는 잘 모르겠지만 형제자매 중 첫째이신 걸로 알고 있고 할아버지도 장손이셨어요. 옛날부터 큰집을 맡으면 할 일이 많잖아요. 할머니가 그것 때문에 건강이 나빠지실 정도였는데 미루어 봤을 때 책임감이 강하신 편인 것 같아요. 그리고 자식들을 무척 사랑하시고 항상 아끼셨어요. 엄마와 할머니가 두 분이 얘기하는 걸 몰래 들어보니 할아버지가 큰외삼촌을 조금 미워하는 마음이 있다는 걸 알게 됐는데, 할머니는 계속 보듬어 주려 하시더라고요. 저한테도 항상 잘해주시고. 따뜻하고 순하셨던 것 같아요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/5-1.svg" alt="" />
        <SubTitle title="² 기억" />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="제가 걷는 걸 좋아해서 웬만한 지하철 한두 정거장 거리는 걸어 다니거든요. 그래서 지리적인 요소나 한번 갔던 길은 곧잘 기억해요. 특히 할머니집은 어릴 때부터 자주 갔기 때문에 어디에 있든 어떻게 가야 할머니 댁에 갈 수 있는지 잘 알고 있습니다."
          a2=""
          br={true}
        />
        <QandA
          q="할머니가 ○○님을 어떻게 맞아주셨나요?"
          a="현관 비밀번호를 알고 있기 때문에 바로 들어가요. “왔어요” 하면 “왔냐” 하시는. 현관에서 신발 벗을 때쯤 (할머니께서) 부엌이나 거실에 계시다가 나오셔서 웃으며 반겨주십니다."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올렸을 때 기억에 남는 방이나 공간이 있을까요?"
          a="지금까지 제가 했던 대답들도 그렇지만 할머니집 하면 떠오르는 기억들이 특별한 사건보다는 평범한 일상의 모습에 가까워요. 할머니집에서 기억에 남을 만한 공간도 마찬가지로 거실이죠. 구조가 특이한 방이 있을 수도 있겠지만 그래도 할머니와 함께했던 기억이 가장 뚜렷한 공간은 거실이네요."
          a2=""
          br={false}
        />
        <QandA
          q="거실에서 보통 뭘 하셨나요?"
          a="그냥 바닥에 엎드려서 그림을 그렸어요. 할머니가 달력을 찢어서 주시고 거기에 그리고 있으면 옆에서 몇 마디 해주시고."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집 풍경이 전체적으로 어땠나요?"
          a="할아버지가 공무원이셨기 때문에 부유하신 것 같아요. 할머니 댁이 아파트에요. 그래서 어릴 때는 명절에 친구들이 다들 시골 내려간다고 하면 부러워했어요. 저는 계속 도시에만 있어서 시골 그 감성을 모르니까. 전형적인 할머니집 같지는 않아요. 집이 60평이 좀 넘을 만큼 넓고 방이 서너 개 되는 것 같고. 지금은 없어졌지만 예전에는 거실에 흔들의자가 있었어요. 앉아서 흔들거리다 뒤로 자빠질까 무서워서 크게 움직이지는 못했어요. 옛날 가구들, 옆으로 회전하는 오래된 의자와 엄청나게 큰 TV도 있었고요. 지금은 더 좋은 걸로 바꾸셨지만요. 그리고 아직까지 쓰시는 옛날 선풍기와 자개장. 특이한 건 할아버지가 수석을 수집하셔서 지금도 할아버지 방 한쪽 벽면이 다 수석으로 차 있어요."
          a2=""
          br={false}
        />
        <QandA
          q="할머니가 쓰시던 물건도 있었나요?"
          a="할머니가 부엌 집기를 되게 많이 모아두셨는데 할아버지가 관여하지는 않으셨어요. 진짜 많아요. 그릇이나 프라이팬, 냄비 같은 것들 중에 거의 안 쓰던 새 물건이나 좋은 게 많았어요. 그래서 저희 집에 쓸 만한 걸 다 들고 와서 사용하고 있고. (...) 할머니 기일에 할아버지가 하신 말씀을 엿들었는데 혼자 사시면서 집안일을 하다 보니 힘든 걸 알겠다고, 그래서 할머니 살아 계실 때 못 도와드렸다는 게 아쉽다고 하시더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올리면 생각나는 특정한 물건이 있나요?"
          a="할머니가 재작년에 돌아가셨는데 짐 정리하다가 진짜 별게 다 나왔거든요. 할머니가 잘 입지도 않았던 옷이나 쓰지 않아서 곰팡이 핀 것들… 그중에 이 곰돌이 키링이 있었어요. 뭔지도 몰랐지만 “이거 귀엽다 나 가질래” 해서 받았어요. 들고 다니면서 볼 때마다 할머니 생각을 하고요. 나중에 저보다 10살쯤 많은 누님 얘기를 들어보니 옛날에 이 키링을 색깔별로 모으는 게 유행이었다고 하시더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집과 관련된 가장 강렬한 기억이 있나요?"
          a="아까 말씀드렸듯이 할머니를 생각하면 뭔가 따뜻하고 긍정적인 감정들이 떠올라요. 그렇지만 제가 고등학교에 다닐 때부터 할머니가 아프셔서 돌아가시기 전까지 교류가 거의 단절됐었어요. 한 3~4년 정도? 안타깝죠. 그 전엔 항상 할머니집 갈 때마다 엄마 몰래 용돈을 주셨는데 그러면 옆에서 엄마가 말려요. “이런 거 안 줘도 된다” “손주들 용돈 주는 재미로 산다” 이런 말들이 오가고. 어릴 때는 이해 못 했어요. 어른들이 주는 거 그대로 받아버리면 속물 같고 엄마 눈치도 보잖아요. 돈을 받아서 좋은 게 아니라 할머니가 계속 엄마 몰래 손에 쥐어 주시던 걸 생각하면 약간 귀엽기도 하고. 그리고 늘 “할머니 딸 속상하게 하지 마라, 속썩이지 마라, 엄마 말 잘 들어라” 이런 말을 헤어질 때마다 하셨어요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/5-2.svg" alt="" />
        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="어릴 때 가던 할머니집과 현재 할머니집의 모습이 다른가요?"
          a="일단 어릴 때만큼 따뜻한 곳은 아니라는 느낌. 사실 어릴 때는 뭘 해도 예뻐해 주시고 동심이나 순수한 마음이 남아 있었는데 나이가 들고 나서는 할머니한테 어리광을 부리지 못했거든요. 머리 컸다고 낯도 좀 가리고 그러다 보니까. 명절 때 어른들께서 집안일을 하고 있으면 도와 드려야 할 것 같은 부담감도 들고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 생각하면 그리워지는 냄새나 소리가 있을까요?"
          a="제가 좋아하는 할머니댁의 모습은 명절 때처럼 북적거리는 것보다는 엄마와 둘이 방문했을 때 조용하던 분위기예요. 21층이라 경치도 좋거든요. 아래가 고스란히 내려다보여서 평화롭죠. 할아버지도 과묵하신 편이라 할머니집의 정적이 오히려 좋아요."
          a2=""
          br={true}
        />
        <QandA
          q="그 순간으로 돌아가고 싶은 할머니집에서의 기억이 있나요?"
          a="저는 어린 시절에 대한 갈망이 크고 그때로 다시 돌아갈 수 없다는 게 안타까워 슬퍼했던 시절이 있어요. 지금 좀 나아졌지만요. 엄마도 옛날 사진을 보다가 제가 10살 때쯤이 가장 좋았을 시기라고 얘기했거든요. 돌이켜 보면 저도 어렸을 때 별 생각이나 부담감 없이 자란 것 같아요. 부족하지 않게 자라서 그런지 모르겠지만 그냥 재밌게 놀고 할머니댁 가도 매일매일 즐겁고. 어릴 때 자주 맡겨졌다고 그랬잖아요. 할머니가 지금보다 젊으셨으니까 제가 마음껏 뛰어놀아도 받아 주셨어요. 그때는 밥만 잘 먹어도 좋아하셨거든요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에서의 경험들이 본인에게 어떤 영향을 끼친 것 같나요?"
          a="분명히 영향은 있을 것 같아요. 엄마는 할머니 딸이니까 엄마도 할머니를 좀 닮았을 거고 저도 엄마 아들이니까 엄마를 닮았을 텐데 행동이나 생각하는 것들이 그대로 넘어온 것 같기도 해요. 할머니가 돌아가시고 나서 가족들끼리 할머니에 관한 얘기를 많이 해 보니 엄마도 성격과 같은 부분에서 할머니의 영향을 많이 받았더라고요. 가족애를 중요하게 여기기 때문에  어떤 행동을 할 때도 할머니의 손자로서, 엄마의 아들로서 비슷한 행동을 해야겠다고 생각해요. (할머니와 엄마가) 따뜻하고 착한 사람들이니까 나도 닮아야지 하는 순간들이 있다는 거죠. 쉽지는 않지만. 가끔 얘기를 나누면 엄마의 순수한 면모가 보이고 저도 아직은 그런 모습이 좀 남아 있는 것 같아요. 물론 세상 물정 모르는 걸 수도 있는데. 그런 가치를 계속 간직하고 싶은 마음이 있습니다."
          a2=""
          br={false}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집에서 유독 꺼려지는 공간이나 물건이 있을까요?"
          a="안 쓰는 방이 두 개 있는데 하나는 진짜 창고처럼 사용하고 있는 데다 들어가기도 쉬워요. 그런데 다른 하나는 아예 끝에 위치한 방이라 옛날 라디오나 지금 쓰지 않는 미싱기 같은 물건들이 쌓여 있어요. 여름이나 겨울옷도 보관되어 있고. 지금 사용하지 않는 것들이 모여 있다 보니까 좀 죽어 있는 느낌이 들었죠. 위치가 구석져서 거실이나 부엌에 사람들이 모여 있어도 소리가 잘 안 들리고 조금 차단된 느낌. 할머니가 계셨을 때는 거기서 다림질을 하셨던 것 같기도 하지만 지금은 거의 죽은 물건들이 쌓여 있는 곳이라 생기가 없죠."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="한 20년 전에 개봉했던 <집으로>라는 영화가 있잖아요. 자식만 바라보고 생각하는 그런 할머니. 그런 모습이 미디어에서 나타나는 전형적인 이미지로 박혀 있는 것 같은데. 요즘은 점점 바뀌고 있겠죠. 잘 모르겠지만."
          a2=""
          br={true}
        />
        <QandA
          q="그런 전형적인 할머니의 모습이 본인의 기억에 영향을 미치기도 했나요?"
          a="영향을 미치지 않은 것 같아요. (외부의 이미지보다) 우리 할머니를 보고 생각한 시간이 더 길기 때문에. 그런 이미지는 어쩌면 로망 같아요. 할머니에 대한 로망이라기보다는 ‘시골집’에 대한 로망. 할아버지 할머니가 시골에 사셨으면 나도 말 타고 소 탔을까."
          a2=""
          br={false}
        />
        <QandA
          q="…대체 무슨 로망이 있으신 건가요?"
          a="시골에 그런 거 없나요?"
          a2=""
          br={false}
        />
        <QandA q="없어요." a="" a2="" br={false} />
      </div>
    </div>
  )
}

export function Naeun() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="6" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="저는 ‘무조건적인 내 편’이라는 생각을 가장 먼저 하는 것 같아요. 또 흰 바지와 진분홍색이 잘 어울리는 사람. 그리고 맛있는 음식을 하는 사람, 늘 따뜻한 사람. 저는 할머니를 그렇게 기억하고 있어요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 함께 시간을 보낸 시기는 주로 언제인가요?"
          a="저는 태어나서 8살 때까지 할머니 옆집에 살았어요. 할머니집을 기점으로 아래는 작은 삼촌이 집을 짓고 살았고 위로는 우리 가족이 집을 짓고 살았기 때문에 걸어서 5분이 채 안 되는 거리였고 할머니 말에 의하면 저흰 ‘눈 뜨면 그냥 본인 집에 와 있는 애들’이었죠. 8살까지는 매일 할머니를 보며 살았고 이후로는 종종 이사를 다녀서 많이 보면 1년에 3~4번 정도? 지금은 그 정도 보고 사는 것 같아요. 가게 되면 길게는 2박3일, 짧게는 1박 2일 정도 있다가 오고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 정서적으로 친밀한 편이신가요?"
          a="할머니에게 느끼는 정서적 친밀감의 정도를 1부터 100까지라고 한다면 100에 가까울 것 같아요. 지금도 할머니와 스스럼없이 함께 잘 정도로요. 언젠가 세상에서 할머니가 사라지면 어떨지 생각해 본 적 있는데 너무 슬프더라고요. 그래서 정서적인 친밀도를 따져 본다면 엄마 아빠만큼이나 가까운 사람이 아닐까."
          a2=""
          br={false}
        />
        <QandA
          q="부모님만큼 가깝다고 느끼는 이유가 있을까요?"
          a="어렸을 때부터 시간을 함께 보내온 사람이기도 하고요. 최근에 기회가 많아져서 할머니와 옛날 사진을 보면서 얘기를 많이 나눴는데, 할머니가 저를 데리고 자주 외출을 하셨더라고요. 제 밑으로 동생이 한 명 있는데 동생은 어려서 엄마와 떨어질 수 없었기 때문에 할머니가 거의 저만 데리고 다니셨죠. 할머니 따라 장도 구경하고 항상 옆에 있었다는 기억이 있으니 친숙하게 여겼던 것 같아요. 저에게 할머니는 언제나 가까운 존재였던 셈이죠. 그리고 할머니 할아버지와 일주일에 한 번은 통화를 했기 때문에 이사를 간 이후에도 그 공백을 느끼지 못한 편이었어요. 저희 집이 이사를 가고 난 후에 할머니가 ‘애를 되게 먼 곳에 떼어 놓고 오는 것 같아서 마음이 헛헛했다’고 하시더라고요. 엄마도 애고 그 애랑 결혼한 남편도 애고 그 밑에 애도 둘인데. 그래서 무척 자주 찾아오셨던 걸로 기억해요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/6-1.svg" alt="" />
        <br />
        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="할머니는 제가 아는 사람 중에 제일 따뜻한 사람 같아요. 대가 없는 친절을 많이 베푸는, 그 대상이 굳이 가족이 아니더라도. 사실 부모 자식 관계는 서로 원하는 바가 있고 자신의 모습을 투영하기 때문에 순수한 애정으로 이루어지기 힘든데 없는데 조부모와 손주 관계는 그렇지 않다고 하잖아요. 그래서 할머니라는 존재가 제게 무조건적인 사랑을 주는 존재라고 인식되기도 했고요. 또 엄마로부터 할머니가 동네의 누군가를 이유 없이 도와줬다는 이야기를 몇 번 들은 적이 있어요. 할머니는 불편하거나 부담스럽지 않게 늘 도움을 주던 사람이라는 인식이 쌓여서 그렇게 생각하게 된 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니의 성격이 본인에게도 영향을 미친 것 같나요?"
          a="사람을 대할 때 의심하지 않는 성향을 갖게 된 것 같아요. 누군가를 대할 때 불편한 감정보다는 좀 더 긍정적인 감정을 많이 갖는 편이죠. 어르신들을 볼 때도 ‘우리 할머니였다면 도와드렸을까’ 하는 마음을 갖게 되는 것 같아요."
          a2=""
          br={false}
        />
        <SubTitle title="² 기억" />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="지금은 길이 새로 뚫렸는데 그전에는 목포 톨게이트를 지나가야 했었어요. 그 길을 따라 제가 다녔던 유치원을 지나면 다음으로 코카콜라 간판을 봐야 하고, 좀 지나면 교회도 보고 다음 마트를 지나서 왼쪽에는 또 교회가 있고, 오른쪽에는 간이역이 있고, 그 옆에 파출소를 지나서 굴다리를 건너요. 바로 오른쪽이 할머니집으로 들어가는 길목이거든요. 그 골목에 나란히 저희 집, 할머니집, 삼촌 집 이렇게 있어요. 할머니집 가는 길 하면 가장 뚜렷하게 기억나는 모습이에요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니가 ○○님을 어떻게 맞아주셨나요?"
          a="집에 막상 들어가면 할머니가 안 보여요. 할머니는 주방에서 뭔가를 계속 만들고 계시니까. 할머니를 보면 한 번 안는 걸로 인사를 했던 것 같아요. “할머니 저희 왔어요” 하면 밥 먹었는지 항상 물어보시죠. 주방에 계시니까 밥을 안 먹었으면 “밥을 먹어라, 좋아하는 거 해놨다” 딱 그 느낌. 할머니 목소리 특유의 톤으로 왔냐고 반겨주시죠."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에 도착했을 때 가장 먼저 보이는 모습은 어떤가요?"
          a="미닫이문이 하나 있어요. 열고 들어가면 할아버지가 큰 소파에 앉아 계세요. 열자마자 보이는 위치에 있기 때문에 제일 먼저 눈에 띄죠. 할아버지와 바로 앞의 TV. 미닫이문을 열기도 전에 형체가 먼저 보여요. 그게 할머니집 들어가는 순간의 첫인상인 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올렸을 때 기억에 남는 방이나 공간이 있을까요?"
          a="소파요. 할머니집에 들어가자마자 보이니까 비중을 가장 많이 차지하는 공간이라고 생각해요. 좀 더 생각해 보면 안방이 제일 기억에 남아요. 옛날 주택의 형태를 보면 바깥 창과 안 창이 따로 있잖아요. 그럼 그사이에 공간이 있는 거 아시죠. 거기서 되게 많이 놀았어요. 안방 문을 잠가두고. 침대 바깥쪽, 문틈 사이, 옷장 안 이런 틈새 공간 때문에 안방이 제일 기억에 남네요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집 내부 인테리어는 어땠나요?"
          a="지금 생각하면 80년대풍의 색이 진한 가구들이 많았던 것 같고. 커튼은 두툼하고 무늬가 화려한 갈색이 섞여 있는 그런 스타일인 데다 나무로 만든 것들이 많았어요. 가장 대표적으로 미닫이창. 방에서 가장 큰 면적을 차지하는 침대나 자개옷장, 화장대도 있고요."
          a2=""
          br={false}
        />
        <QandA
          q="가장 기억나는 공간인 안방이라고 하셨잖아요. 안방의 모습은 어땠나요?"
          a="안방은 문을 열고 들어가면 할머니 화장대가 있어요. 그리고 돌침대가 있고 그 옆에 ㄱ자로 창이 있어요. 침대 맞은편에는 한 3단 정도 되는 수납장이 하나 있고 그 옆에는 되게 큰 옷장이 있어요. 나무로 만든, 순서대로 열고 닫아야 하는 옷장. 옆에 잠글 수 있게 노리개 달린 열쇠가 꽂혀 있는 옷장 아시죠. 그 옆에 옷걸이가 있고 할머니 할아버지가 자주 입으시는 옷들 여기에 걸어두셨어요. 스위치도 옛날 스위치에요."
          a2=""
          br={true}
        />
        <QandA
          q="기억에 남는 물건도 있을까요?"
          a="그리고 기차가 생각나요. 저희 할머니집 앞에 기차가 지나가거든요. 언젠가 할머니집에서 기차를 타고 집으로 올라올 일이 있었는데 기차에서 할머니집이 보이더라고요. 할머니가 서 계시면 보일 것 같은 정도로 가까웠는데 앞으로도 기차 탈 일이 있으면 무조건 이 생각을 하지 않을까요. 지금도 탈 일이 있으면 그쪽을 보게 되거든요. 집 자체가 할머니의 상징이고 할머니의 공간이니까. (...) 할아버지가 최근에 돌아가셨는데 기차에서 할머니집을 보고 있다가 맞은편 차창을 보면 할아버지 묘가 보여요. 그게 되게 기분이 이상하더라고요. 양쪽에 인사하고 오는 기분. 할아버지 돌아가신 지 얼마 안 됐을 때는 엄청 많이 울었던 것 같아요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집과 관련된 가장 강렬한 기억이 있나요?"
          a="어렸을 때 감이 너무 먹고 싶었나 봐요. 할머니집에 감나무들이 많은데 제가 사촌 언니를 꼬셔서 포댓자루를 하나 끌고 올라가서 그 감을 다 땄어요. 익었을 줄 알고 떫은 감까지 전부 다. 근데 까치밥까지 다 따와서 그때 이렇게 다 따오면 어떻게 하냐고, 이거 먹지도 못한다고 하셨던 게 기억나요. 그 기억이 대표적이에요."
          a2=""
          br={false}
        />
        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="기억 속 할머니집과 현재 할머니집이 다른 점이 있나요?"
          a="가장 큰 건 할아버지의 존재 유무일 것 같고요. 왜냐하면 정말 최근에 돌아가셨거든요. 그리고 두 번째는 가족의 부재로 인해서 바뀐 가구 배치인 것 같아요. 할아버지의 엄마인 증조할머니가 저 고등학교 때 돌아가셨는데 그전에는 증조할머니가 앉아 계시던 1인 소파가 따로 있었어요. 할머니가 원래 쓰시던 방도 따로 있었고요. 그런 것들이죠. 버려야지 했던 케케묵은 어항을 비롯해 할아버지가 돌아가시면서 버린 것들이 몇 개 있어요. 그런 것들이 기억과 좀 달라요. 정말 어렸을 때는 여기에 할머니 소파가 있었고, 여기는 원래 할머니방이라 TV가 있었는데 증조할머니가 돌아가시고는 그 방을 이제 옷방이나 손님방으로 쓴다든지 하는 것들."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 생각하면 그리워지는 냄새나 소리가 있을까요?"
          a="할머니 소리인 것 같아요. 할머니집 문 열고 들어갔을 때 할머니 할아버지가 말하는 소리, 반겨주는 소리가 가장 그리운 소리일 것 같고요. 또 아까 말한 대로 잘 때 들리는 기차 소리나 미닫이문이 한 번에 열리지 않고 덜컥 걸리는 그런 느낌들. 그게 할머니 집 들어갔을 때 처음 느끼는 느낌이라 그리워지는 감각적인 기억이 아닐까 싶어요."
          a2=""
          br={false}
        />
        <QandA
          q="일상에서 할머니를 떠올리게 하는 특정한 물건이 있나요?"
          a="흰 바지와 흰색 신발이요. 제가 최근에 할머니가 원래 쓰시던 선글라스를 “어차피 안 쓸 거면 나 줘” 하면서 가지고 왔는데 앞으로 그걸 볼 때마다 계속 할머니를 추억하지 않을까요. 그리고 할머니가 여행 다니시면서 찍었던 사진들을 핸드폰에 찍어서 담아 왔는데 할머니가 그때는, 이때는 이랬다는 얘기를 많이 해주셨어요. 할아버지 물건 정리할 때 이런저런 얘기를 많이 했거든요. 그래서 할머니 선글라스가 앞으로 좀 생각날 것 같아요. 흰 바지랑 선글라스."
          a2=""
          br={false}
        />
        <QandA
          q="멋쟁이시네요."
          a="멋쟁이죠. 어렸을 때 항상 할머니 할아버지가 올라오셔서 계절 옷을 사주셨거든요. 저희 할머니는 오실 때 항상 그 하얗고 밝은 바지를 입으셨어요. 저희 할머니는 굉장히 멋을 좋아하셨기 때문에. 그런 것들이 할머니 하면 상징적으로 떠오르는 것 같아요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/6-2.svg" alt="" />
        <br />
        <QandA
          q="그 순간으로 돌아가고 싶은 할머니집에서의 기억이 있나요?"
          a="전 항상 5살 때로 돌아가고 싶어요. 왜 5살이냐면 제가 가졌던 세상 안에서 못할 게 없었던 시절이라서요. 모든 게 즐겁고 모든 사람이 다 내가 아는 울타리에 있고 내 의사를 표현할 수 있고. 그래서 항상 돌아갈 수만 있다면 5살 무렵인데요. 할머니 집 마당에서 놀거나, 옥상에서 놀거나, 방에서 놀거나 하던 게 전부였기 때문에 그 시절로 돌아가고 싶은 게 아닐까요."
          a2=""
          br={true}
        />
        <QandA
          q="그런 어린 시절과 할머니집에서의 기억들이 ○○님께 어떤 영향을 끼쳤나요?"
          a="내가 만약 내 아이를 낳아서 키운다면 걔도 자기가 하고 싶은 걸 다 했으면 좋겠다는 생각이 들어요. 충분히 사랑받고 자연물과 놀고 이런 삶을 살았으면 좋겠다. 왜냐하면 제가 가지고 있는 어린 시절의 기억이 친구들과 얘기하며 비교해 보면 생각보다 특이한 편이더라고요. 그래서 그냥 ‘그랬으면 좋겠다’고 생각해요."
          a2=""
          br={false}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집을 떠올릴 때 꺼려졌던 공간이나 물건이 있나요?"
          a="할머니집 대문 들어가자마자 왼쪽에 문이 하나 있거든요. 여기가 좌식 화장실이에요. 근데 한 번도 들어가 본 적이 없어요. 열려 있어서 안을 본 기억은 있는데 들어가거나 쓴 기억은 없어요."
          a2=""
          br={false}
        />
        <QandA
          q="화장실이 가고 싶으면 어떡해요?"
          a="집 안에 있는 화장실을 쓰죠. 그 화장실은 급한 분들이 밖에서 용무를 볼 때 썼던 것 같은데 지금도 할머니집이 아니라고 느껴지는 공간이에요. 제 손으로 열어본 기억이 없어요. 어떻게 생겼는지는 어렴풋이 그려지지만 굳이 안 써요. 제가 재래식 화장실을 엄청 꺼리는 사람은 또 아니거든요. 하지만 거기는 그래요."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="소위 할머니 냄새라고 말하는 콤콤한 냄새를 무척 불편하게 표현한 것들에 대한 기억이 많아요. 노인분들이 사는 삶을 굉장히 단조롭고 외롭고 공허하게 그리고, 본인을 관리하지 않아 콤콤한 냄새가 나고 어두운 그런 이미지로 비추잖아요. 제가 가지고 있는 생각과 조금 다르니까 ‘그렇지 않은데’ 라고 생각했던 기억이 나요."
          a2=""
          br={true}
        />
        <QandA
          q="그런 이미지가 ○○님의 할머니를 떠올릴 때 영향을 미치기도 하나요?"
          a="할머니집을 떠올릴 때 제가 기억하는 것과는 다르니까 새로웠던 것 같아요. 할머니한테 나는 냄새가 한 번도 불편하다고 생각해 본 적 없었거든요. 만약 제가 할머니의 존재를 언젠가 부정하게 되는 순간이 온다면 그렇게 느껴질 수도 있을까? 생각해 본 적은 있었던 것 같아요. 할머니가 어렵고 불편해지는 시기가 오면 나도 그럴까 느낄까. 친밀감을 느끼는 대상일수록 (그 사람이) 가지고 있는 게 마냥 좋아 보이잖아요. 불편함을 가지고 있으면 좋은 것도 안 좋게 보이니까."
          a2=""
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화에 대한 소감이 있다면."
          a="할아버지가 작년 12월에 돌아가셨어요. 그러고 나서 할머니와 함께하는 시간이 생각보다 길었어요. 할머니가 저희 집에 와 계시기도 했고 저도 할머니집에 굉장히 오래 가 있기도 했고. 할머니가 가지고 계시던 오래된 앨범을 같이 꺼내보면서 할머니 18살 때 사진, 약혼 사진, 결혼 사진 이런 걸 다 처음 봤어요. 그러니까 제가 기억하는 할머니의 모습이 아닌 할머니라는 사람의 삶을 처음 들여다본 거죠. 할아버지가 돌아가시고 나서 보니 (기억할 만한 것들이 비교적 없어서) 아쉽더라고요. 나중에 할머니에 대한 기억도 흐릿해지고 나면 이 인터뷰에서 했던 말을 통해 다시 기억을 되짚게 되지 않을까 싶어요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/6-3.svg" alt="" />
      </div>
    </div>
  )
}

export function Hajeong() {
  return (
    <div className="flex flex-wrap items-center justify-center">
      <TitlePic name="7" />
      <div className="w-[684px] leading-[26px]">
        <SubTitle title="¹ 할머니와 나" />
        <QandA
          q="할머니를 생각하면 무엇이 떠오르나요?"
          a="가장 먼저 생각나는 건 음식. 이건 변하지 않아요. 우리 외할머니는 음식과 관련이 많은 사람이었어요. 할머니가 작은 실내 포장마차를 하셨을 만큼 음식 솜씨가 좋으셨죠. 가족들이 모여서 외할머니 얘기를 할 때 가장 먼저 등장하는 단어가 바로 음식이에요. 그중에서도 가장 먼저 떠오르는 걸 묻는다면 저는 주저하지 않고 ‘만두’와 ‘김치’라고 말할 수 있어요."
          a2=""
          br={false}
        />
        <QandA
          q="만두와 김치가 가장 먼저 떠오르는 이유가 있나요?"
          a="저희 외가는 가족끼리 자주 모이는 편이고 함께 하는 행사가 많은 집이었어요. 으레 그렇듯 가을에 모여서 김장을 하고 이때 만든 김장 김치로 설 즈음 꼭 만두를 만들어요. 온 가족이 만두를 만드는데 그날은 정말 가능한 사람이라면 어른이고 애고 간에 상관없이 모이죠. 저도 학교가 끝나고 할머니집에 와서 만두를 만들다가 학원에 갔다 다시 할머니집으로 오고 그랬어요. 대체 만두를 얼마나 만들길래 하루 종일 하냐 물으실 수 있는데, 어린애들 목욕도 시킬 정도로 큰 대야에다 만두소만 한 바가지를 만들었거든요. 무슨 만둣집이나 만두 공장처럼. 만두를 만들면서 쪄 먹기도 하고 국을 끓여 먹기도 하면서 아침에 시작한 일이 저녁이 돼서야 끝났어요."
          a2="이제 매년 하지는 않지만 아직까지 그 시기가 오면 자연스레 생각나고 (가족끼리) 종종 모여서 만두를 빚기 때문에 할머니 이야기를 시작하면 김장 김치와 만두를 빼고는 얘기를 못 하죠."
          br={false}
        />
        <QandA
          q="다른 곳에서 만두를 먹을 때도 생각나겠네요."
          a="맞아요. 그래서 딴 데 가서 김치만두를 잘 못 먹어요. 아무래도 집에서 만든 걸 먹다가 밖에 나가서 먹으면 입맛에 안 맞으니까."
          a2=""
          br={false}
        />
        <QandA
          q="지금 할머니는 안 계시고 남은 가족들과 함께 음식 얘기를 하시는 건가요?"
          a="그렇죠. 그렇게 됐죠."
          a2=""
          br={true}
        />
        <QandA
          q="할머니와 정서적으로 친밀하셨나요?"
          a="어떻게 표현할까 고민을 많이 했는데 단순하게 말씀드릴게요. 할머니가 돌아가신 지 10년 가까이 되어 가는데도 아직까지 엄마랑 산책할 때나 대화할 때 할머니가 굉장히 보고 싶다고 얘기해요. 살아 계셨으면 너무 좋았을 텐데, 이젠 직장인이 됐으니까 용돈을 더 드릴 수도 있을 텐데, 지금은 고스톱을 칠 줄 아니까 돈도 잃어드려 가면서 같이 칠 수도 있을 텐데, 하는 생각을 많이 해요."
          a2="방금 떠오른 할머니와의 에피소드를 하나 말씀드릴게요. 할머니가 어린 나이에 시집을 오셨는데, 구박을 받은 건 아니었지만 또 대접받는 위치도 아니셨나 봐요. 할머니는 저희에게 밥상 차려 주실 때 반찬을 전부 접시에 옮겨 담아 주곤 하셨는데요. 반찬통 채로 주셔도 된다고 했더니 “내가 대접을 받고 자라지 않아서 손녀딸들은 대접해 주고 싶어”라고 말씀하셨던 적이 있어요. 제가 아주 어렸을 때 들었던 말이었는데도 기억에 많이 남네요. 그래서 엄마도 밥을 차려 주실 때는 항상 접시에 반찬을 담아 주시고, 저도 누군가를 대접할 때 가능한 옮겨 담으려 하는데 그게 할머니로부터 온 습관인 것 같아요."
          br={true}
        />
        <img src="/assets/interview/7-1.svg" alt="안기" />
        <br />

        <QandA
          q="○○님이 보기에 할머니는 어떤 성격이셨나요?"
          a="할머니의 성격에 대해서는 생각해 본 적 없었던 것 같아요. 어렸을 때부터 저한테 ‘할머니’ 그 자체였던 분이셔서. 스무 살이 넘어 제 일을 하게 되고 자아가 확립되고 나서야 ‘나의 부모님이 이런 사람이구나’ 생각하게 되잖아요. 저는 그런 가치관이 확립되기 전에 할머니가 돌아가셨기 때문에 당신의 성격에 대해 자세히 말씀드리지는 못하겠네요. 그래도 옛날 기억을 떠올려 봤을 때는 성격이 급하셨던 것 같아요. 밥을 정말 빠른 속도로 드셨거든요. 손주나 자식들께는 아주 상냥하신 건 아니었지만 그래도 부드러운 편이셨어요. 나중에 생각해 보니 억센 면이 있으셨는데 제 앞에서는 그런 모습을 보여주지 않으신 편인 것 같기도 하고요."
          a2=""
          br={true}
        />
        <QandA
          q="다른 가족들이 기억하는 할머니는 어떤 분이셨나요?"
          a="자식에 대한 사랑이 지극하신 분이었다, 지금도 다들 그렇게 말씀하세요. 저도 그렇게 생각하고요. 손주 손녀 사랑이 지극하셨어요."
          a2=""
          br={false}
        />
        <QandA
          q="그러면 다른 가족들과 할머니에 대한 기억이 일치하는 편이시네요."
          a="맞아요. 저희 집은 그래요. 그리고 음식을 잘하는 분이었다고 다들 입을 모아서 얘기하는데, 저는 사실 기억이 잘 안 나요. 제가 너무 어려 기억이 없던 때는 할머니가 집에서 냉면 육수까지 직접 뽑으셨다고 하고, 동네에서 홍어 무침을 제일 잘하셔서 잔치 날마다 불려 가셔서 홍어를 무쳐 주고 왔다고 하시더라고요. 저는 구체적으로 기억하지 못하지만 어른들은 알고 계시는 거죠. ‘장모님이 무쳐주신 홍어 정말 맛있었지’ 이런 식으로요. 그렇게 기억되는 분이세요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/7-3.svg" alt="추억" />

        <SubTitle title="² 기억" />
        <QandA
          q="할머니집 가는 길의 모습이 기억나세요?"
          a="네, 제 최초의 기억이 할머니집에 가는 기억이거든요. 몇 살 때인지는 몰라요. 엄마 아빠와 시골을 다녀왔는데 짐을 내리는 사이에 제가 갑자기 없어졌다는 거예요. 진짜 난리가 난 거죠. (부모님이) 울며불며 여기저기 뛰어다니다가 혹시나 해서 집에 들어갔는데 할머니께 전화가 왔대요. 너네는 어쩌려고 애만 이렇게 혼자 보냈냐고. 익숙한 길이니까 아기가 혼자 아장아장 그 길을 걸어서 할머니집까지 혼자 갔더라는 거죠. 어렴풋이 주택가 담벼락 사이를 걸어간 기억이 있거든요."
          a2=""
          br={false}
        />
        <QandA
          q="할머니집이 가까웠나요?"
          a="되게 가까웠어요. 저는 할머니와 같은 동네에 살지 않은 기간이 훨씬 짧아요. 태어났을 때부터 그랬고, 제가 할머니의 막내딸이 낳은 첫째 딸이라 하루가 멀다 하고 어머니가 저를 업어 들고 가까운 할머니 댁으로 가셨다더라고요. 외가 쪽이 다 인천에 사시기도 했고. 아침에 할머니가 “사골국 끓였으니 먹고 가라” 하시면 혼자 할머니댁 가서 먹고 유치원 가고 그랬죠. 그때는 걸어서 5분 정도 걸리는 거리였으니까."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올렸을 때 기억에 남는 특정한 방이나 공간이 있나요?"
          a="할머니집을 떠올리면 옛날 빌라와 아파트가 생각나는데요. 빌라는 뒤에서 얘기하고 할머니가 돌아가시기 전까지 살았던 아파트에 대해 말씀드릴게요. 안방이 할머니 방이었는데 할아버지와 함께 쓰다가 할아버지께서 먼저 돌아가시고 홀로 쓰셨어요. 세간 살림이 단출했죠. 장롱, 할머니가 쓰시던 환자 침대, 그리고 TV 서랍장 같은 거랑 TV, 옷걸이와 거울 이 정도였어요. 저는 할머니가 마지막에 사셨던 그 공간이 가장 기억에 남아요."
          a2=""
          br={false}
        />
        <QandA
          q="가장 기억에 남았던 이유가 있나요?"
          a="할머니가 가장 오래 계셨던 공간이었기 때문에 그런 것 같아요. 항상 그 침대에 앉거나 누워 TV를 보셨어요. 하루 종일 트로트 프로그램이 나오고 있었고. 가끔 그걸 같이 멍하니 보기도 하고 수다를 떨기도 하고 그랬었어요. 그때는 생각하지 못했는데 이제 보니 우리 할머니는 TV 보시면서 무슨 생각을 하셨을까 궁금하더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집의 인테리어는 어땠나요?"
          a="옛날 빌라는 이제 와서 생각하면 정말 옛날 집이었네, 하는 느낌이에요. 화장실 들어갈 때 턱이 되게 높았고 주방 겸 거실 공간에 다이아몬드 모양의 바닥재가 있었다는 것 정도가 기억나네요. 아파트로 이사하시고 나서는 할머니 방보다는 막내 삼촌 방이 더 떠오르는데요. 왜냐하면 막내 삼촌 방이 이전 세입자분들의 따님 방이었나 봐요. 벽지가 키티 무늬였는데 도배를 새로 안 하고 그냥 그대로 사셨어요."
          a2=""
          br={false}
        />
        <QandA
          q="장성한 삼촌께서 러블리한 방을 가지게 되셨네요."
          a="문을 열면 컴퓨터 하는 삼촌 주변으로 키티가 만연했죠. 방이 되게 사랑스럽다고 생각했는데 삼촌은 아랑곳하지 않으셨어요. 생각보다 신경을 안 쓰시더라고요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집에 도착했을 때 가장 먼저 보이는 장면은 어땠나요?"
          a="빌라가 있던 골목에 주택이 아주 많았어요. 그래서 그 앞에다가 평상을 두고 주민들끼리 자주 소통하셨거든요. 때로는 집까지 가지 않아도 골목길에 들어설 때부터 저기 평상에 우리 할머니가 앉아 계신 게 보였죠. 그때부터 할머니한테 달려가면 “우리 강아지”로 시작되죠. 할머니의 마중이."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 떠올리면 생각나는 특정한 물건이 있나요?"
          a="앞서 말했듯이 할머니 얘기를 할 때 음식이 빠질 수가 없는데요. 좀 쑥스럽긴 하지만 할머니집 냉장고가 그렇게 떠올라요. 양문형 말고 위아래로 여닫는 냉장고라서 냉동실을 열면 위 칸, 아래 칸으로 나뉘어 있었는데 만두를 빚는 날이면 이 아래 칸을 할머니가 싹 비워놓으셨어요. 거기에 비닐봉지 깔고 만든 만두를 오와 열을 맞춰서 올려요. 올리고 그 위에 또 올리고."
          a2="그리고 이건 엄마한테 들은 이야기인데요. 어렸을 때부터 제가 먹을 거에 집착이 좀 강했어요. 할머니네 냉장고를 열면 항상 한쪽에 요구르트가 있었는데 제가 막내딸이 낳은 첫째 딸이고 바로 위 사촌과도 터울이 나서 오랜만에 보는 아기라 많이 예뻐하셨대요. 그래서 자꾸 뭔가를 먹이고 싶으셨던 거예요. 그렇게 끝도 없이 요구르트가 애기 배 속으로 들어갔죠. 제가 너무 먹어댄 나머지 엄마가 냉장고를 못 열게 하셨고, 저는 열어보고 싶은데 엄마가 무서워 그 냉장고를 부여잡고 울었다고 하더라고요. 얼마나 애처롭게 울던지 할머니 할아버지가 저 조그만 게 먹으면 얼마나 먹는다고 문을 못 열게 하냐고 (말씀하셨다고) 아직도 이 얘기를 하세요."
          br={true}
        />
        <QandA
          q="할머니집과 관련된 가장 강렬한 기억이 있나요?"
          a="할머니집에 가는 건 항상 저한테 즐거움이었던 것 같아요. 가서 특별히 무언가를 하지 않더라도. 어렸을 때 혼자 한 달 동안 할머니집에 산 적도 있고, 방학이거나 엄마가 동생을 출산하느라 저를 보살피기 힘드실 때 할머니집에서 통학하기도 하고 그랬거든요. 물론 엄마 아빠도 절 아끼셨지만 동생이 둘이나 있다 보니 할머니집에 가면 어리광을 더 받아 줄 사람이 있다고 생각했죠. 할머니는 라면 먹고 싶다 하면 끓여 주시고 만화책 보고 싶다 하면 빌려 오는 김에 간식도 사 오라고 용돈을 쥐여 주시는 분이셨으니까. 붕어빵 장사도 잠깐 하셨는데 별로 춥지 않은 날에는 따라 나가서 할머니가 붕어빵 굽는 모습을 가만히 지켜보다가 몰래 하나 구워 보기도 했어요. 할머니집에는 항상 내 편이 있다고 생각했기 때문에 지금도 웃음이 나고 행복한 기억들이 많아요."
          a2=""
          br={true}
        />
        <img src="/assets/interview/7-2.svg" alt="안기" />

        <SubTitle title="³ 노스탤지어" />
        <QandA
          q="어릴 때 갔던 할머니집을 지금도 방문할 수 있나요?"
          a="내부까지 들어갈 수는 없지만 그 건물은 볼 수 있어요. 제가 다니는 병원 가는 길에 그 빌라가 있고 제가 산책하는 길에 그 아파트가 있기 때문에. 같은 동네에서 오래 살고 있어서 현재 저의 동선이 할머니가 종종 산책하시던 동선과 겹치거든요. 그래서 어떻게 변했는지 보려고 일부러 할머니집 주변을 지나갈 때도 있어요. 이제 할머니가 다른 이웃분들과 수다 떠시던 평상도 없고 할머니도 안 계시지만요."
          a2=""
          br={true}
        />
        <QandA
          q="할머니집을 생각하면 그리워지는 냄새나 소리가 있을까요?"
          a="그리운 소리가 있어요. 아파트에 사실 때 유리로 된 중문 열 때 덜컹하는 소리가 났거든요. 그럼 안방에 계시던 할머니는 보이지 않아도 소리 때문에 누가 온 걸 아셨어요. 저뿐만 아니라 엄마도 그렇고 다들 그 소리가 좀 듣고 싶다는 이야기를 요즘도 해요."
          a2=""
          br={true}
        />
        <QandA
          q="일상에서 할머니를 떠올리게 하는 특정한 물건이 있나요?"
          a="저는 수국을 보면 할머니 생각이 참 많이 나요. 삼촌 두 분과 함께 사셨는데 돌아가시기 한 달 전쯤부터 각자의 사정으로 집에 들어오지 못하는 일이 잦아져서 그 집에 할머니만 계셨거든요. 할머니가 조금 무서움을 타셨다고 해야 하나. 밤에 혼자 있기가 무섭다고 하셔서 엄마나 저나 시간 되는 사람들이 한 번씩 번갈아 가면서 찾아갔다가 주무실 때쯤 돌아오곤 했어요. 어느 날 학원 끝나고 할머니집에 가려고 버스를 타러 가는 길에 꽃집이 있었는데 그날따라 수국이 되게 예뻐 보이는 거예요. 할머니는 꽃을 좋아하시니까 한 번 사다 드리면 좋겠다 싶어서 사 들고 가서 할머니가 누우셔도 보이는 곳에 수국을 꽂아 두었더니 보시고는 “수국이 참 예쁘다. 우리 손녀 얼굴만큼 곱고 예쁘다”라고 하셨어요. 그렇게 말씀하시는 표정이나 목소리가 너무 슬프게, 우시듯 말씀하셔서 엄마랑 저도 조금 울었어요. 할머니는 그런 소리를 그렇게 슬프게 하냐고 타박 아닌 타박을 하면서 세 모녀가 같이 울었던 기억이 있는데, 그러고 나서 얼마 안 있다가 돌아가셨거든요. 엄마도 저도 수국을 보면 그렇게 할머니 생각을 많이 해요."
          a2=""
          br={true}
        />
        <QandA
          q="그 순간으로 돌아가고 싶은 할머니집에서의 기억이 있나요?"
          a="있죠. 가족들이 같은 동네에 살다 보니까 굳이 얘기하지 않아도 모이는 경우가 있었거든요. 대표적으로 좋아하는 모임 중 하나가 명절 혹은 엄마 사촌 분들이 종종 놀러 오실 때였어요. 의정부 식구들이라고 불렀는데 그분들이 오시는 날은 늦게 자도 되고 저와 놀아주는 사람들이 많은 날이었어요. 나를 예뻐하는 사람들이 많이 오고 자주 보지 못하던 사촌 언니 오빠도 보는 날. 언니 오빠들이 같이 카드 게임이나 윷놀이를 하면서 놀아주거나 손잡고 오락실을 갔던 기억이 있기 때문에 지금 생각해도 설레고 재밌던 날이라 그때로 다시 돌아가고 싶어요."
          a2=""
          br={true}
        />
        <QandA
          q="이런 할머니집에서의 경험들이 본인에게 영향을 미친 것 같나요?"
          a="영향을 미치죠. 제가 결혼과 출산을 할지 안 할지 모르겠지만 애를 가지게 된다면 그 아이에게도 이런 기억이 있었으면 하는 생각을 하고 있어요. 지금도 여유가 되면 가족끼리 다 같이 모여 만두를 만들고 고스톱도 치고 밥이라도 한 끼 먹고 하는 게 아직까지 이어지고 있기 때문에 그게 저의 근간이 된다고 생각해요. 그리고 그 근간은 제가 아이를 낳으면 아주 희미하게나마 이어질 거고요. 우스갯소리로 결혼식에 관한 얘기를 할 때 “하객이 너무 많은 건 싫고 스몰 웨딩 하고 싶어”라고 하면서 수를 헤아려 보니 가족만 50명이더라고요. 이번 생엔 아무래도 스몰 웨딩은 안 되겠다고 서로 이야기한다니까요."
          a2=""
          br={true}
        />
        <SubTitle title="⁴ 언캐니" />
        <QandA
          q="할머니집에서 왠지 모르게 꺼려졌던 공간이나 물건이 있었나요?"
          a="할머니가 아파트로 이사 가시기 전 사셨던 빌라의 베란다요. 베란다에 식물이 정말 많았거든요. 정확히 기억나지 않지만 어린 마음에 밀림 같다고 생각할 정도로 푸릇푸릇한 것들이 되게 많았어요. 사실 무서울 게 없었는데도 저는 괜히 거기에 들어가고 싶지 않다고 생각했던 것 같아요. 영화에서나 보던 커다란 벌레들 있잖아요. 좀 과장해서 포켓몬스터의 독침붕 같은 게 있을지도 모른다고 생각했었어요."
          a2="또 다른 장소는 할머니집 옷장 속. 옛날 장롱은 한 칸만 있지 않잖아요. 이불 꺼내는 칸은 자주 열어서 그 속을 봤기 때문에 괜찮은데 나머지 칸에 대한 두려움이 좀 있었던 것 같아요. 닫혀 있는 문 저 안에 뭐가 있을까 하는 궁금증도 있었지만 왠지 속을 쉽사리 들여다볼 수 없을 것 같은 느낌이라고 해야 하나. 그 칸이 열리는 날이면 할머니 뒤에 숨어서 흘깃거리며 봤던 것 같아요."
          br={true}
        />
        <QandA
          q="할머니집에 대한 본인과 다른 가족들의 기억이 다른 경우가 있었나요?"
          a="달랐다기보다는 할머니에 대해 내가 잘 몰랐구나 싶었던 부분이 있는데요. 엄마가 말하는 할머니는 감이 되게 좋아서 동네에서 박 반장이라고 불렸던 분이더라고요. TV에서 수사반장 방영할 때라 그런 별명이 생겼다는데, 할머니를 닮아 엄마도 감이 좋다는 말씀을 지금도 농담처럼 하시거든요. 혹은 할머니가 생각보다 고집이 무척 세신 분이었다든가. 엄마를 통해 알고 나서 의외라고 생각했어요. 가장 놀랐던 건 할머니 음식 맛의 비밀인데요. 모든 음식이 그렇다는 건 아닌데, 그게 아마 찌개였던 것 같아요. 할머니 거동이 좀 불편해지신 후에 요리를 하는 데도 힘이 드니까 엄마가 가서 할머니 지시대로 요리를 하셨는데 할머니가 하신 말씀이 “네 마음대로 하는데 마지막에 다시다 한 스푼만 넣어”라고. (웃음) 모든 음식에 다시다를 넣으신 건 아니지만 찌개 맛의 비결이 그 한 스푼이었나 봐요. 알게 된 후에 역시 할머니의 손맛은 다시다라는 얘기를 했었죠."
          a2=""
          br={false}
        />
        <QandA
          q="그 ‘비결’을 알게 되고 난 후 심경이 어떠셨나요."
          a="물론 할머니집 한쪽에 항상 다시다가 놓여 있었던 건 알았지만 우리 할머니도 넣으실 때가 있었다는 걸 깨닫고 좀 충격을 받긴 했죠. 그렇지만 어쨌든 맛있으니까. 우리 할머니 음식 최고니까. 엄마가 되게 좀 원통해하시긴 하셨어요. 내 음식이 맛이 없는 게 아닌데 다시다에 밀리다니 하시면서."
          a2=""
          br={true}
        />
        <QandA
          q="미디어 혹은 주변에서 보고 들었던 ‘할머니’의 모습은 주로 어떤가요?"
          a="아무래도 대표적으로 떠오르는 모습은 남녀 차별이 아닐까요. 손주와 손녀를 차별한다든가 하는. 저는 그걸 보고 되게 놀랐어요. 친구들과 할머니에 대한 얘기를 할 때 좋은 추억을 가진 친구들도 많았지만 꺼려하는 친구들도 꽤 있었거든요. 저는 할머니와 사이가 굉장히 좋은 편이었기 때문에 할머니에 대해 나쁘게 말하면 안 된다고 얘기를 했던 적도 있는데 생각이라는 걸 할 나이가 됐을 때 알게 된 거죠. 다 우리 할머니 같지는 않으시구나. 민감한 부분이라 말씀드리기 조심스럽긴 하네요."
          a2=""
          br={true}
        />
        <QandA
          q="마지막으로 오늘 대화에 대한 소감이 있다면."
          a="할머니에 대해서 누군가에게 얘기하는 게 굉장히 오랜만인 것 같아요. 어쩌면 처음일 수도 있고요. 할머니와 내가 이렇게 긴밀하고 친밀한 관계였다고 이야기하는 게 거의 처음이기 때문에 아주 즐거운 경험이었다고 생각해요. ‘코코’라는 애니메이션 다 보셨을 것 같은데 그곳은 누군가가 자신을 얼마나 기억해 주느냐에 따라 망자가 아예 사라져 버리기도 하고 잘살게 되기도 하는 세계잖아요. 근데 이렇게 우리 할머니에 대해 말씀드림으로써 여러분도 만두를 먹거나 다시다를 볼 때 ‘그런 할머니가 계셨지’ 하고 생각하실 수도 있잖아요. 만약에 할머니가 지금 가 계신 곳이 코코의 세계관과 비슷하다면 우리 할머니한테 조금 더 힘이 되지 않을까요. 우리 할머니를 조금이라도 기억해 주는 사람이 한 분이라도 더 계신다면 저는 너무 좋을 것 같아요."
          a2=""
          br={false}
        />
      </div>
    </div>
  )
}
