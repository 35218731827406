import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "./App.css"
import About from "./pages/About"
import Community from "./pages/Community"
import House from "./pages/House"
import Interview from "./pages/Interview"
import Vending from "./pages/Vending"
import Main from "./pages/Main"
import Navbar from "./components/Navbar"

function App() {
  return (
    <div className="w-full h-full text-white bg-black">
      <Router>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/community" element={<Community />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/house" element={<House />} />
          <Route path="/vending" element={<Vending />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
