import { NavLink, useLocation, useNavigate } from "react-router-dom"

export const menu = [
  {
    name: "VISITING GRANDMA",
    path: "/house",
  },
  {
    name: "VENDING MACHINE",
    path: "/vending",
  },
  {
    name: "INTERVIEW",
    path: "/interview",
  },
  {
    name: "NOSTALGIA OF YOURS",
    path: "/community",
  },
]

export default function Navbar() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div>
      <div>
        <div className="bg-black h-[140px] lg:h-[74px] sm:h-[100px] text-gray-400 text-[13px] mb-5 fixed w-full lg:flex lg:justify-between p-5 z-20">
          <div className="w-full pt-5 lg:w-auto sm:pt-0">
            <img
              src="assets/logo/liminal_letter.svg"
              alt="logo"
              onClick={() => navigate("/")}
              className="cursor-pointer w-[156px] h-[31px]"
            />
          </div>

          <div className="items-center justify-between sm:hidden">
            <div className="flex justify-between py-2">
              <div
                className={
                  "/house" === pathname
                    ? "text-white"
                    : "text-[#8A8A8A] hover:text-white"
                }
              >
                <NavLink to="/house">VISITING GRANDMA</NavLink>
              </div>
              <div
                className={
                  "/vending" === pathname
                    ? "text-white"
                    : "text-[#8A8A8A] hover:text-white"
                }
              >
                <NavLink to="/vending">VENDING MACHINE</NavLink>
              </div>
              <div
                className={
                  "/interview" === pathname
                    ? "text-white"
                    : "text-[#8A8A8A] hover:text-white"
                }
              >
                <NavLink to="/interview">INTERVIEW</NavLink>
              </div>
            </div>
            <div className="flex justify-between">
              <div
                className={
                  "/community" === pathname
                    ? "text-white"
                    : "text-[#8A8A8A] hover:text-white"
                }
              >
                <NavLink to="/community">NOSTALGIA OF YOURS</NavLink>
              </div>
              <div className="w-[30%] items-center flex justify-end">
                <NavLink
                  to="/about"
                  className={
                    pathname === "/about"
                      ? "text-white"
                      : "text-[#8A8A8A] hover:text-white"
                  }
                >
                  ABOUT PROJECT
                </NavLink>
              </div>
            </div>
          </div>

          <div className="w-[75%] hidden lg:visible lg:flex">
            <div className="flex items-center justify-between w-[65%]">
              {menu.map(({ name, path }) => {
                return (
                  <div
                    key={name}
                    className={
                      path === pathname
                        ? "text-white"
                        : "text-[#8A8A8A] hover:text-white"
                    }
                  >
                    <p className="hover:text-white">
                      <NavLink to={path}>{name}</NavLink>
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="flex items-center justify-end w-[35%]">
              <NavLink
                to="/about"
                className={
                  pathname === "/about"
                    ? "text-white"
                    : "text-[#8A8A8A] hover:text-white hover:text-white"
                }
              >
                ABOUT PROJECT
              </NavLink>
            </div>
          </div>

          <div className="flex items-center justify-between invisible sm:visible sm:py-2 lg:hidden">
            <div
              className={
                "/house" === pathname
                  ? "text-white"
                  : "text-[#8A8A8A] hover:text-white"
              }
            >
              <NavLink to="/house">VISITING GRANDMA</NavLink>
            </div>
            <div
              className={
                "/vending" === pathname
                  ? "text-white"
                  : "text-[#8A8A8A] hover:text-white"
              }
            >
              <NavLink to="/vending">VENDING MACHINE</NavLink>
            </div>
            <div
              className={
                "/interview" === pathname
                  ? "text-white"
                  : "text-[#8A8A8A] hover:text-white"
              }
            >
              <NavLink to="/interview">INTERVIEW</NavLink>
            </div>
            <div
              className={
                "/community" === pathname
                  ? "text-white"
                  : "text-[#8A8A8A] hover:text-white"
              }
            >
              <NavLink to="/community">NOSTALGIA OF YOURS</NavLink>
            </div>
            <div className="flex items-center justify-end">
              <NavLink
                to="/about"
                className={
                  pathname === "/about"
                    ? "text-white"
                    : "text-[#8A8A8A] hover:text-white hover:text-white"
                }
              >
                ABOUT PROJECT
              </NavLink>
            </div>
          </div>
        </div>
        <div className="fixed z-20 w-full h-5 bg-gradient-to-b from-black to-transparent top-[140px] sm:top-[100px] lg:top-[74px]"></div>
      </div>
      <div className="bg-black w-full h-[150px] sm:h-[100px]  lg:h-[74px]"></div>
    </div>
  )
}
